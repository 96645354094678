/**
 * 审批流模块接口定义
 */
import type {
  RollbackRequest,
  TerminatedRequest,
  CreateProjectBossDecisionRequest,
  ClosingComplianceRequest,
  MeetingVO,
  CreateProjectVoteRequest,
  UpdateProjectVoteRequest,
  ProjectVoteVO,
  CreateProjectMeetingContentRequest,
  UploadDDReportRequest,
  UpdateDDExecutionStateRequest,
  UploadICDecisionRequest,
  UpdateICExecutionStateRequest,
  UpdateLFExecutionStateRequest,
  ProjectBossDecisionVO,
  TriggerRequest,
  UpdateBossDecisionExecutionStateRequest,
  BusinessMemberVO,
  Folder,
  SkipMeetingRequest,
  ExecutionVO,
  MeetingContentVO,
  SkipRequest,
  ProjectOCVoteOverrideRequest,
} from '@shared/api/interface';
import type { IConfig } from './types';

interface WorkflowCommonParamsType {
  projectId: string;
  executionId: string;
}
interface ExecutionCommonParamsType extends WorkflowCommonParamsType {
  targetNodes: string[];
  comment: string;
}

export const queryMeetingDetail: IConfig<
  {
    projectId: string;
    contentId: string;
  },
  Required<MeetingContentVO>
> = {
  summary: '获取项目上会详情',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/meetings/contents/{contentId}',
};

export const setProjectMeeting: IConfig<
  {
    projectId: string;
    executionId: string;
  } & CreateProjectMeetingContentRequest,
  void
> = {
  summary: '发起上会',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/meetings',
};

export const skipMeetings: IConfig<WorkflowCommonParamsType & SkipMeetingRequest, boolean> = {
  summary: '跳过上会',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/meetings/skip',
};

export const editProjectMeeting: IConfig<
  {
    projectId: string;
    contentId: string;
  } & CreateProjectMeetingContentRequest,
  Required<MeetingVO>
> = {
  summary: '编辑项目上会',
  method: 'PUT',
  url: '/api/v1/investment/projects/{projectId}/workflows/meetings/contents/{contentId}',
};

export const getProjectMeetingOptions: IConfig<
  {
    projectId: string;
  },
  Required<MeetingVO>[]
> = {
  summary: '获取可以上会的例会信息',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/meetings',
};

export const getMeetingFiles: IConfig<{ projectId: string }, Folder> = {
  summary: '获取上会文件',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/meetings/files',
};

export const createProjectVote: IConfig<
  CreateProjectVoteRequest & {
    projectId: string;
  },
  { id: string }
> = {
  summary: '创建投票',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/oc-votes',
};

export const getVoteDetail: IConfig<{ projectId: string; voteId: string }, Required<ProjectVoteVO>> = {
  summary: '获取投票详情',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/oc-votes/{voteId}',
};

export const editVote: IConfig<UpdateProjectVoteRequest & { projectId: string; voteId: string }, void> = {
  summary: '编辑投票详情',
  method: 'PUT',
  url: '/api/v1/investment/projects/{projectId}/workflows/oc-votes/{voteId}',
};

export const getVoteOcMembers: IConfig<{ voteId?: string }, any> = {
  summary: '获取投票的OC成员',
  method: 'Get',
  url: '/api/v1/investment/projects/workflows/oc-votes/ocs',
};

export const ocVote: IConfig<
  { projectId: string; voteId: string; ghostlogin?: string } & CreateProjectVoteRequest,
  boolean
> = {
  summary: 'OC投票',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/oc-votes/{voteId}',
};

export const getBossDecisionDetail: IConfig<{ projectId: string }, ProjectBossDecisionVO> = {
  summary: '获取老板决策相关的数据，比如 projectDeal tpp是否跟投等',
  url: '/api/v1/investment/projects/{projectId}/workflows/boss-decisions',
  method: 'GET',
};

export const uploadBossDecision: IConfig<
  CreateProjectBossDecisionRequest & {
    projectId: string;
    executionId: string;
  },
  ExecutionVO
> = {
  summary: '上传老板决策',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/boss-decisions',
};

export const executionBossDecisionState: IConfig<
  UpdateBossDecisionExecutionStateRequest & {
    projectId: string;
    executionId: string;
  },
  boolean
> = {
  summary: '老板决策 更新执行阶段',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/boss-decisions/execution-state',
};

export const updateBossDecision: IConfig<
  CreateProjectBossDecisionRequest & {
    projectId: string;
    executionId: string;
  },
  boolean
> = {
  summary: '更新老板决策',
  method: 'PUT',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/boss-decisions',
};

export const getBossDecisionFiles: IConfig<{ projectId: string }, Folder> = {
  summary: '获取老板决策文件',
  method: 'get',
  url: '/api/v1/investment/projects/{projectId}/workflows/boss-decisions/files',
};

export const getProjectMembers: IConfig<{ projectId: string }, BusinessMemberVO> = {
  summary: '获取项目中的财法税，用于老板决策更新执行阶段人员卷入',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/boss-decisions/members',
};

export const uploadDdReport: IConfig<WorkflowCommonParamsType, Required<UploadDDReportRequest>> = {
  summary: '上传 DD 报告',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/dd/upload-report',
};

export const getDdFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取 DD 阶段相关文件',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/dd/files',
};

export const updateDdExecution: IConfig<WorkflowCommonParamsType & UpdateDDExecutionStateRequest, void> = {
  summary: 'DD 更新执行状态',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/dd/execution-state',
  method: 'POST',
};

export const updateTsExecution: IConfig<
  ExecutionCommonParamsType & {
    folderId: string;
  },
  void
> = {
  summary: '更新执行状态',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/ts/execution-state',
  method: 'POST',
};

export const uploadTsFolder: IConfig<
  WorkflowCommonParamsType & {
    tsFolderId: string;
  },
  boolean
> = {
  summary: '上传TS文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/ts/upload-attachment',
  method: 'POST',
};

export const uploadICMemo: IConfig<{ executionId: string; projectId: string } & UploadICDecisionRequest, ExecutionVO> =
  {
    summary: '上传 IC Memo',
    url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/ic-decisions/upload-decision',
    method: 'POST',
  };

export const icDecisionUpdateExecution: IConfig<
  { projectId: string; executionId: string } & UpdateICExecutionStateRequest,
  void
> = {
  summary: 'IC 更新执行状态',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/ic-decisions/execution-state',
  method: 'POST',
};

export const lfDecisionUpdateExecution: IConfig<
  {
    projectId: string;
    executionId: string;
  } & UpdateLFExecutionStateRequest,
  boolean
> = {
  summary: 'LF 更新执行状态',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/lf/execution-state',
  method: 'POST',
};

export const updateProjectSecret: IConfig<{ projectId: string; isSecret: string }, boolean> = {
  summary: '更新项目保密性',
  url: '/api/v1/investment/projects/{projectId}/secret',
  method: 'PUT',
};

export const executionTrigger: IConfig<WorkflowCommonParamsType & TriggerRequest, boolean> = {
  summary: '启动、进入下一阶段等',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/trigger',
  method: 'POST',
};

// 跳过当前阶段
export const skipCurrentStage: IConfig<WorkflowCommonParamsType & SkipRequest, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/skip',
  method: 'POST',
  summary: '跳过当前阶段',
};

export const terminatedProject: IConfig<WorkflowCommonParamsType & TerminatedRequest, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/terminated',
  method: 'POST',
  summary: '终止当前项目',
};

export const projectProgressStatus: IConfig<
  {
    projectId: string;
    progressStatus: string;
  },
  boolean
> = {
  url: '/api/v1/investment/projects/{projectId}/progressStatus',
  method: 'PUT',
  summary: '推进状况标记',
};

export const recoverProject: IConfig<WorkflowCommonParamsType, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/recover',
  method: 'POST',
  summary: '恢复当前项目',
};

export const hasten: IConfig<WorkflowCommonParamsType, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/hasten',
  summary: '催办',
  method: 'POST',
};

export const withdraw: IConfig<WorkflowCommonParamsType & RollbackRequest, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/withdraw',
  summary: '撤回',
  method: 'POST',
};

export const rollback: IConfig<WorkflowCommonParamsType & TriggerRequest, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/rollback',
  summary: '回退',
  method: 'POST',
};

//  ====== 文件相关 ======
export const getTsFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取TS相关文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/ts/files',
  method: 'GET',
};

export const getIcFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取IC相关文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/ic-decisions/files',
  method: 'GET',
};

export const getLfFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取LF相关文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/lf/files',
  method: 'GET',
};

export const getClosingFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取入库文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/closing/files',
  method: 'GET',
};

export const updateIcFolder: IConfig<UploadICDecisionRequest & WorkflowCommonParamsType, boolean> = {
  summary: '更新IC决策',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/ic-decisions/decision',
  method: 'PUT',
};

export const getVoteFolder: IConfig<{ projectId: string }, Folder> = {
  summary: '获取投票文件',
  url: '/api/v1/investment/projects/{projectId}/workflows/oc-votes/files',
  method: 'GET',
};

export const ocVoteOverride: IConfig<WorkflowCommonParamsType & ProjectOCVoteOverrideRequest, boolean> = {
  summary: 'oc投票-override',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/oc-votes/override',
  method: 'POST',
};

export const syncTpp: IConfig<{ projectId: string }, boolean> = {
  summary: '同步TPP',
  url: '/api/v1/investment/projects/{projectId}/tppFollowUp',
  method: 'PUT',
};

export const offlineTrade: IConfig<{ projectId: string; isOfflineTrade: string }, boolean> = {
  summary: '线下交割',
  url: '/api/v1/investment/projects/{projectId}/offlineTrade',
  method: 'PUT',
};

export const complianceCheck: IConfig<{ projectId: string }, boolean> = {
  summary: '是否满足入库检查条件',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/workflows/closing/compliance',
};

export const uploadComplianceFiles: IConfig<{ projectId: string } & ClosingComplianceRequest, boolean> = {
  summary: '完成合规性检查',
  method: 'POST',
  url: '/api/v1/investment/projects/{projectId}/workflows/closing/compliance',
};

export const meetingNextStage: IConfig<TriggerRequest & WorkflowCommonParamsType, boolean> = {
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/meetings/execution-state',
  method: 'POST',
  summary: '更新上会执行阶段',
};
