import { BlindWatermark } from 'watermark-js-plus';

let watermarkRef = null;

type WatermarkProps = {
  // 容器选择器
  parent: string;
  // 文案
  content: string;
};

export function createWatermark(props: WatermarkProps) {
  const { parent = 'body', content = '' } = props;
  if (watermarkRef) {
    watermarkRef.destroy();
  }
  watermarkRef = new BlindWatermark({
    width: 250,
    height: 300,
    rotate: 45,
    contentType: 'multi-line-text',
    content,
    parent,
    mode: 'blind',
  });
  watermarkRef.create();
}
