/* eslint-disable @typescript-eslint/no-unused-vars */
import { createModel } from '@rematch/core';
import { Api, ApiConfig } from '@shared/api';
import { setLocal, STORAGE_KEY, clearLocal } from '@src/libs/localStorage';
import { cloneDeep } from 'lodash';

import type { RootModel } from '.';

type GlobalState = {
  home: any;
  detail: any;
  user: any;
  isAuthenticated: boolean;
};

type LoginParams = {
  account: string;
  password: string;
  randStr: string;
  ticket: string;
};

export const global = createModel<RootModel>()({
  name: 'global',
  state: {
    user: null,
    isAuthenticated: false,
  } as unknown as GlobalState,
  reducers: {
    updateUserInfo(state, payload) {
      return {
        ...state,
        user: payload,
      };
    },
    updateIsAuthenticated(state, payload) {
      return {
        ...state,
        isAuthenticated: payload,
      };
    },
    updateHomeData(state, payload) {
      return {
        ...state,
        home: payload,
      };
    },
    updateSheetDetail(state, payload) {
      return {
        ...state,
        detail: payload,
      };
    },
    setState(state, payload: Partial<GlobalState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getUserInfo() {
      try {
        const data = await Api.request(ApiConfig.timPortal.getUserInfo);
        this.updateUserInfo(data);
        this.updateIsAuthenticated(true);
        return data;
      } catch (err) {
        // token过期 清掉token
        if (err.code === 100900) {
          clearLocal(STORAGE_KEY.TOKEN);
        }
        return Promise.reject(err);
      }
    },
    async login(params: LoginParams) {
      const data = await Api.request(ApiConfig.timPortal.login, params);
      setLocal(STORAGE_KEY.TOKEN, data.accessToken);
      const userInfo = await this.getUserInfo();
      return userInfo;
    },
    async logout() {
      const data = await Api.request(ApiConfig.timPortal.logout);
      this.clearLoginState();
      return data;
    },
    async clearLoginState() {
      clearLocal(STORAGE_KEY.TOKEN);
      this.updateIsAuthenticated(false);
    },
    async getHomeData() {
      const data = await Api.request(ApiConfig.timPortal.getHomeData);
      this.updateHomeData(data);
    },
    async getSheetDetail(recordId) {
      const data = await Api.request(ApiConfig.timPortal.getSheetDetail, { recordId });
      this.updateSheetDetail(data);
      // axios返回的数据是只读的 复制一份新的
      return cloneDeep(data);
    },
  }),
});
