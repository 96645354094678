// 语言分类
export enum LANGUAGE {
  zh = 'zh-CN',
  en = 'en-US',
}

// TIM -- PR相关rtx放开国际化
// https://tapd.woa.com/tim_oa/prong/stories/view/1010143951114942627
export const LANGUAGE_PR_RTX = [
  {
    userId: 184289,
    userNameEn: 'sbhatia',
  },
  {
    userId: 245224,
    userNameEn: 'shukaku',
  },
  {
    userId: 183974,
    userNameEn: 'pranayreddy',
  },
  {
    userId: 317476,
    userNameEn: 'robertcole',
  },
  {
    userId: 48493,
    userNameEn: 'brody',
  },
  {
    userId: 315686,
    userNameEn: 'afree',
  },
];
