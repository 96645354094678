/* eslint-disable no-param-reassign */
import { $i18next } from '@shared/i18n';
import axios, { AxiosRequestConfig } from 'axios';
import { makeKeyChain, parse, each, isArray, createSafeExp, assign } from 'ts-fns';
import type { IObj } from '@shared/typings/object';
import { source, fetch } from 'algeb';
import { getInterceptor } from '@shared/api/interceptor';
import { report } from '@shared/libs/aegis/sdk';
import { isH5, isWeb, isIos, isAndroid } from '@shared/utils/env';
import { addQueryString } from '@shared/utils/url';
import { LogType } from './aegis/type';
import { AppEventName, GlobalEventBus } from './event-bus';

export const axiosInstance = axios.create({
  baseURL: '',
});

const isDevelopmentEnv = process.env.NODE_ENV === 'development';

axiosInstance.interceptors.request.use(
  (instanceConfig: AxiosRequestConfig) => {
    Object.assign(instanceConfig.headers, {
      // 网关会话超时不要返回302，返回401，方便处理 https://iwiki.woa.com/p/4006958986
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Requested-With': 'XMLHttpRequest',
    });

    return instanceConfig;
  },
  (error) => Promise.reject(error),
);

export function httpGet<T = any>(url: string): Promise<T> {
  return sendRequest({ method: 'get', url });
}

export function httpPost<T = any>(url: string, data: IObj): Promise<T> {
  return sendRequest({ method: 'post', url }, data);
}

export function httpPut<T = any>(url: string, data: IObj): Promise<T> {
  return sendRequest({ method: 'put', url }, data);
}

export function httpDelete<T = any>(url: string, data?: IObj): Promise<T> {
  return sendRequest({ method: 'delete', url }, data);
}

export function httpGetWithConfig<T = any>(url: string, config?: Partial<AxiosRequestConfig>): Promise<T> {
  return sendRequest({ method: 'get', url, ...config });
}

export function httpPostWithConfig<T = any>(url: string, data: IObj, config?: Partial<AxiosRequestConfig>): Promise<T> {
  return sendRequest({ method: 'post', url, ...config }, data);
}

export function httpPutWithConfig<T = any>(url: string, data: IObj, config?: Partial<AxiosRequestConfig>): Promise<T> {
  return sendRequest({ method: 'put', url, ...config }, data);
}

export function httpDeleteWithConfig<T = any>(url: string, config?: Partial<AxiosRequestConfig>): Promise<T> {
  return sendRequest({ method: 'delete', url, ...config });
}

export enum HTTP_ERROR {
  NO_PERMISSION = 100101,
  NO_PROCESS_REPORT = 201203,
  NO_LEGAL_DATABASE_PROCESS_REPORT = 320208,
}

export function sendRequest(arg: Partial<AxiosRequestConfig>, postData?: IObj) {
  let response = false;

  const interceptor = getInterceptor('default');
  if (interceptor) {
    response = interceptor(arg, postData) as any;
  }

  // 使用默认
  if (response === false) {
    return httpRequest(arg, postData);
  }

  return response;
}

export class HttpError extends Error {
  public status: number;
  public statusText: string;
  constructor(message, status?: number, statusText?: string) {
    super(message);
    this.status = status;
    this.statusText = statusText;
  }
}

function httpRequest(arg: Partial<AxiosRequestConfig>, postData?: IObj, isRetry = false) {
  const { method, headers, ...restConfig } = arg;
  let { url } = arg;
  const fn = method.toLowerCase();

  const extract = async (body) => {
    // 支持response.text 或者 response.blob
    if (typeof body === 'string' || body instanceof Blob) {
      return body;
    }

    const { code, data, fieldMapping, msg } = body;

    if (code !== 100000) {
      // TODO 根据后端给的code信息来决定弹出什么错误提示
      const error = new Error(msg);

      error.name = code;

      error.valueOf = () => body;

      // 基于cause弹出错误信息
      // 如果被catch，就不会走弹出逻辑了
      error.cause = new Error(`[http] ${msg}`);

      // 提供更多调试信息
      if (process.env.NODE_ENV !== 'production') {
        const message = `${method} ${url} ${
          postData ? `+ ${JSON.stringify(postData, null, 4)}` : ''
        } -> ${JSON.stringify(body, null, 4)}`;
        console.error(message);
      }

      throw error;
    }

    await transformDataWithSchemaFields(data, fieldMapping);
    transformDataWithPolicies(data);
    transformDataWithWhiteList(data);

    return data;
  };

  const errorHandler = (e: Error & { response?: any }, reportParams?: any) => {
    let msg = e?.message;
    // Network Error是axios抛出的错误，此时可能网络连接不上
    const statusCode = e?.response?.status;
    msg =
      msg === 'Network Error'
        ? $i18next.t('shared:ba444a62:网络连接异常，请检查网络设置{{_0}}', { _0: statusCode ? ` (${statusCode})` : '' })
        : $i18next.t('shared:c5fa5891:请求异常{{_0}}', { _0: msg ? ` (${msg})` : '' });
    const statusText = e?.response?.statusText;
    const err = new HttpError(msg, statusCode, statusText);
    // 提供更多调试信息
    if (process.env.NODE_ENV !== 'production') {
      const message = $i18next.t('shared:66d69ab8:[http]: {{_0}} {{_1}} 请求异常 {{_2}}', {
        _0: method,
        _1: url,
        _2: e.message ? e.message : '',
      });
      console.error(message);
    }

    // https://cloud.tencent.com/document/product/1464/58563
    report({
      msg,
      level: LogType.AJAX_ERROR,
      ext2: reportParams
        ? JSON.stringify({
            ...reportParams,
            err: e,
          })
        : undefined,
    });

    // 401需要重试
    if (statusCode === 401) {
      // 进入重试逻辑
      if (!isRetry) {
        // 微信下访问时，index.html可能被缓存，接口请求在网关层面可能鉴权失败
        // Web下智能网关会话超时，需要重新鉴权
        if ((isH5() || isWeb()) && typeof window !== undefined) {
          return httpRequest(arg, postData, true);
        }
        if (!isH5() && (isIos() || isAndroid())) {
          const [clearCookieCallback] = GlobalEventBus.getEvents(AppEventName.clearCookie);
          if (typeof clearCookieCallback === 'function') {
            return (clearCookieCallback as () => Promise<boolean>)().then(() => httpRequest(arg, postData, true));
          }
          return httpRequest(arg, postData, true);
        }
        return Promise.reject(err);
      }
      if ((isH5() || isWeb()) && typeof window !== undefined && !isDevelopmentEnv) {
        if (isH5()) {
          // 避免report还没发出去
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const newUrl = addQueryString(location.href, { __t: +new Date() }); // 当前页面直接刷新
            window.location.replace(newUrl);
          }, 0);
          // return Promise.reject(err);
        } else {
          // web下发送事件广播，由组件进行处理
          GlobalEventBus.trigger(AppEventName.gatewaySessionTimeout);
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  };

  if (postData) {
    const { url, requestParams: postRequestData } = parseUrl(arg.url, postData);
    return axiosInstance[fn](url, postRequestData, { headers, ...restConfig, _isRetry: isRetry }).then(
      (res) => extract(res.data),
      (e) => errorHandler(e, { method, url }),
    );
  }

  if (arg.params) {
    const urlAndParams = parseUrl(arg.url, arg.params);
    url = urlAndParams.url;
    arg.params = urlAndParams.requestParams;
  }

  return axiosInstance[fn](url, { headers, ...restConfig, ...arg, _isRetry: isRetry }).then(
    (res) => extract(res.data),
    (e) => errorHandler(e, { method, url, params: arg.params }),
  );
}

const fieldMappingCache = {};

// TODO 支持通过keys获取局部的，而非全部的信息
const FieldsByKeys = source(async () => {
  const { data } = await axiosInstance.get(`/api/v1/fields/all`);
  return data;
}, []);

/**
 * 拉取自定义配置的字段信息
 * @param fields
 * @returns
 */
import { flatObject } from '@shared/utils/util';
import { isDebug } from '@src/helper/env';
import { getLanguage } from '@src/libs/i18n';
import { LANGUAGE } from '@shared/constants/language';
import { getGhostUser } from '@src/helper/ghostuser';
export async function fetchCustomFields(fields) {
  const keysNotInCache = fields.filter((item: string) => !fieldMappingCache[item]);
  if (!keysNotInCache.length) {
    return fields.map((key) => fieldMappingCache[key]);
  }

  // const keys = uniqueArray(keysNotInCache);
  try {
    const customFields = await fetch(FieldsByKeys);
    const metas = {};
    customFields.forEach((item) => {
      const { code } = item;
      metas[code] = item;
    });

    Object.assign(fieldMappingCache, metas);
    return fields.map((key) => fieldMappingCache[key]);
  } catch (e) {
    console.error(e);
  }
}

/**
 * 字段配置逻辑，将接口返回的数据，基于fieldMapping进行转换
 */
export async function transformDataWithSchemaFields(data, fieldMapping) {
  // 把没有拉取的部分拉取回来
  const customFieldKeys = Object.values(fieldMapping);
  await fetchCustomFields(customFieldKeys);

  const customFieldPaths = Object.keys(fieldMapping);
  customFieldPaths.forEach((keyPath) => {
    const chain = makeKeyChain(keyPath);
    const tail = chain.pop();
    const customFieldKey = fieldMapping[keyPath];

    if (chain.length) {
      const nested = parse(data, chain);
      const deep = chain.filter((item) => item === '*').length;
      if (deep && nested) {
        let nodes = nested;
        for (let i = 0, max = deep - 1; i < max; i++) {
          const items = [];
          nodes.forEach((node: any[]) => {
            items.push(...node);
          });
          nodes = items;
        }

        nodes.forEach((node) => {
          if (!node) {
            return;
          }
          // eslint-disable-next-line no-param-reassign
          node._meta = node._meta || {};
          // eslint-disable-next-line no-param-reassign
          node._meta[tail] = fieldMappingCache[customFieldKey];
        });
      } else if (nested) {
        nested._meta = nested._meta || {};
        nested._meta[tail] = fieldMappingCache[customFieldKey];
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      data._meta = data._meta || {};
      // eslint-disable-next-line no-param-reassign
      data._meta[tail] = fieldMappingCache[customFieldKey];
    }
  });

  return data;
}

function transformDataByKey(data, key, to = key) {
  const walk = (data) => {
    if (data && typeof data === 'object') {
      let found = null;
      each(data, (value, k) => {
        if (key === k) {
          found = value;
          return;
        }
        walk(value);
      });
      if (found) {
        data[`_${to}`] = found;
      }
    }
  };
  walk(data);
  return data;
}

export function transformDataWithPolicies(data) {
  return transformDataByKey(data, 'policies');
}

export function transformDataWithWhiteList(data) {
  return transformDataByKey(data, 'whiteProperties', 'whiteList');
}

export function parseUrl(str, obj) {
  let url = str;
  let requestParams = null;
  if (isArray(obj)) {
    requestParams = [...obj];
  } else {
    requestParams = { ...obj };
  }
  Object.keys(obj).forEach((key) => {
    const regex = new RegExp(createSafeExp(`{${key}}`), 'g');
    if (str.match(regex)) {
      url = url.replace(regex, obj[key]);
      delete requestParams[key];
    }
  });
  return { url, requestParams };
}

(function() {
    
axiosInstance.interceptors.request.use((config) => {
  let { data } = config;
  const lang = getLanguage();
  // 设置语言头
  // eslint-disable-next-line no-param-reassign
  config.headers = Object.assign(config.headers, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'accept-language': LANGUAGE[lang] || 'zh-CN',
  });

  if (config.method === 'post') {
    const formType = 'application/x-www-form-urlencoded';
    const contentType = config.headers.post['Content-Type'];

    // 自动将带File对象的data参数转换为FormData对象
    if (!(data instanceof FormData)) {
      const flatObj = flatObject(data);
      const hasFile = Object.values(flatObj).find((v) => v instanceof File);
      if (hasFile) {
        const formData = new FormData();
        Object.keys(flatObj).forEach((key) => {
          const value = flatObj[key];

          // FormData形式传入null会变成字符串
          if (value !== null && value !== undefined) {
            formData.append(key, value);
          }
        });

        data = formData;
        // eslint-disable-next-line no-param-reassign
        config.data = data;
      }
    }

    if (data instanceof FormData && contentType.indexOf(formType) !== -1) {
      // eslint-disable-next-line no-param-reassign
      config.headers.post['Content-Type'] = 'multipart/form-data';
    }

    // // application/x-www-form-urlencoded格式
    // else if (contentType.indexOf(formType) !== -1) {
    //   config.data = qs.stringify(data);
    // }
  }

  return config;
});

// ghostloign
if (isDebug()) {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.url.includes('ghostlogin')) {
      const ghost = getGhostUser();
      if (ghost) {
        assign(config, 'params.ghostlogin', ghost);
      }
    }

    return config;
  });
}

  } ())