// TODO: 要考虑分包加载问题
import './router/browser';

import ReactDOM from 'react-dom';
import type { ComponentType } from 'react';
import { MessagePlugin } from 'tdesign-react';
import { createErrorToastBootstrap } from '@shared/utils/error';
import { MessageQueueService, type MessageType } from '@shared/modules/common/services/message/message-queue.service';
import { alert, confirm, toast } from '@src/libs/tdesign-plugin';
import { isDebug } from '@src/helper/env';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function render(selector: string, Component: ComponentType) {
  ReactDOM.render(<Component />, document.querySelector(selector));
}

// 通过 MessageQueue 实现全局消息通知、弹出
const messageQueue = MessageQueueService.instance();
messageQueue.subscribe((type: MessageType, message, title?: string) => {
  if (type === 'alert') {
    const obj = title ? { message, title } : message;
    return new Promise<void>((r) => alert(obj, r));
  }
  if (type === 'confirm') {
    const obj = title ? { content: message, title } : message;
    return new Promise<void>((resolve, reject) => confirm(obj, resolve, reject));
  }
  toast(message, type);
});

const createWarnBy = createErrorToastBootstrap((method, message) => {
  MessagePlugin[method](message);
});

const warnPromise = createWarnBy({
  http: 'error',
  error: 'error',
  warn: 'warning',
});

const warnError = createWarnBy({
  error: 'error',
  warn: 'warning',
});

/**
 * 全局处理请求错误提示
 */
window.addEventListener('unhandledrejection', (e) => {
  // 对于空，直接忽略即可
  if (!e.reason) {
    e.preventDefault();
    return;
  }
  if (e.reason instanceof Error) {
    const error = e.reason;
    warnPromise(
      error,
      // 避免被上报到监控系统
      () => e.preventDefault(),
    );
  }
});

/**
 * 全局处理错误提示
 */
window.addEventListener('error', (e) => {
  const { error } = e;
  warnError(
    error,
    // 避免被上报到监控系统
    () => e.preventDefault(),
  );
});

// 为方便查看当前是哪一个构建ID，加上这个值，可以在测试线上看到信息
if (isDebug()) {
  // @ts-ignore
  window.__ciBuildId = `https://devops.woa.com/console/pipeline/zhiyan-tim-ma/p-${process.env.BK_CI_PIPELINE_ID}/detail/b-${process.env.BK_CI_BUILD_NUM}`;
}
