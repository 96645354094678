import type { IOption, IOptions } from '@shared/typings/option';
import { FIELD_OPTION_KEY } from '@shared/constants/server-hard-code';
import type { ListDataQueryParamsType } from '@shared/typings/components/list';
import type { IPagination } from '@shared/typings/pagination';
import type {
  IPage,
  FundVO,
  FoFProjectVO,
  OptionObject,
  ProjectMembersVO,
  EditableMemberVO,
  FundMembersVO,
  FoFFinancingRoundVO,
  FoFDealVO,
  FoFProjectInvestorCorporationVO,
  FoFProjectLfDataVerifyApprovalVO,
  ApproveRequest,
} from '../interface/index';
import type { IConfig } from './types';
import { getFieldOption } from './common';

export const getFofPipeline: IConfig<{ columns?: string }, IPage<any>> = {
  summary: '获取基金列表',
  method: 'get',
  url: '/api/v1/fof/funds/pipelines',
  mock: false,
};

export const getFofPipelineDetail: IConfig<{ fundId?: string }, FundVO> = {
  summary: '获取投前基金详情',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}',
  mock: false,
};

export const deleteFofPipelineDetail: IConfig<{ fundId?: string }, FundVO> = {
  summary: '删除基金详情',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}',
  mock: false,
};
export const followFofFund: IConfig<{ fundId?: string }, boolean> = {
  summary: '关注基金',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/follows',
  mock: false,
};

export const cancelFollowFofFund: IConfig<{ fundId?: string }, boolean> = {
  summary: '取消关注基金',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}/follows',
  mock: false,
};

export const getFofPipelineFilterDataSource: IConfig<{ type?: FIELD_OPTION_KEY }, OptionObject[]> = {
  ...getFieldOption,
  summary: '拉取APP FOF投前筛选数据',
};

export const getFofPipelineAppointments: IConfig<{ fundId?: string }, IPage<any>> = {
  summary: '获取基金约见记录列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/appointments',
  mock: false,
};

export const deleteFofPipelineAppointments: IConfig<{ fundId: string; appointmentId: string }, IPage<any>> = {
  summary: '删除约见记录',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}/appointments/{appointmentId}',
  mock: false,
};

export const getGps: IConfig<
  ListDataQueryParamsType,
  IPagination & {
    records: any[];
  }
> = {
  url: '/api/v1/fof/gps',
  method: 'get',
  summary: '获取GP列表',
};

// ================================ 投中 ===========================================
export const getFofKickoffFilterDataSource: IConfig<{ type?: FIELD_OPTION_KEY }, OptionObject[]> = {
  ...getFieldOption,
  summary: '拉取APP FOF投前筛选数据',
};

export const getFofKickoff: IConfig<{ columns?: string }, IPage<any>> = {
  summary: 'FOF投中列表',
  method: 'get',
  url: '/api/v1/fof/projects/kickoffs',
  mock: false,
};

export const getFofKickoffDetail: IConfig<{ projectId?: string }, FoFProjectVO> = {
  summary: '获取投中详情',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}',
  mock: false,
};

export const getFofKickoffDetailEdit: IConfig<{ projectId?: string }, FoFProjectVO> = {
  summary: '获取fof项目详情编辑数据',
  method: 'get',
  url: `/api/v1/fof/projects/{projectId}/edit`,
  mock: false,
};

export const updateFofKickoffDetail: IConfig<{ projectId?: string }, any> = {
  summary: '更新 fof 项目详情数据',
  method: 'put',
  url: `/api/v1/fof/projects/{projectId}`,
  mock: false,
};

export const deleteFofKickoffDetail: IConfig<{ projectId?: string }, any> = {
  summary: '更新 fof 项目详情数据',
  method: 'delete',
  url: `/api/v1/fof/projects/{projectId}`,
  mock: false,
};

export const getFofKickoffMembers: IConfig<{ projectId?: string }, ProjectMembersVO> = {
  summary: '获取投中成员',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/members',
  mock: false,
};

export const editFofKickoffMembers: IConfig<{ projectId?: string }, any> = {
  summary: '保存投中成员',
  method: 'post',
  url: '/api/v1/fof/projects/{projectId}/members',
  mock: false,
};

export const getFofKickoffEditMembers: IConfig<{ projectId?: string }, EditableMemberVO> = {
  summary: '获取投中编辑成员',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/members/edit',
  mock: false,
};

export const getFofKickoffEditHistoryMembers: IConfig<{ projectId?: string }, EditableMemberVO> = {
  summary: '获取投中编辑历史成员',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/history-members/edit',
  mock: false,
};

export const editFofKickoffHistoryMembers: IConfig<{ projectId?: string }, any> = {
  summary: '保存投中历史成员',
  method: 'post',
  url: '/api/v1/fof/projects/{projectId}/history-members',
  mock: false,
};

export const getFofKickoffRounds: IConfig<{ projectId?: string }, any[]> = {
  summary: '获取项目的轮次列表',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/rounds',
  mock: false,
};
export const getFofKickoffRoundDeals: IConfig<{ projectId?: string; roundId?: string }, FoFDealVO[]> = {
  summary: '获取轮次对应交易列表',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/rounds/{roundId}/deals',
  mock: false,
};

export const deleteFofDeal: IConfig<{ projectId: string; id: string }, any[]> = {
  summary: '删除fof投中交易',
  method: 'delete',
  url: '/api/v1/fof/projects/{projectId}/deals/{id}',
  mock: false,
};

export const getFofProjectRound: IConfig<
  { projectId: string; roundId: string; currencyCode?: string },
  FoFFinancingRoundVO
> = {
  summary: '获取项目的轮次详情',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/rounds/{roundId}',
  mock: false,
};

export const getFofProjectRoundEdit: IConfig<{ projectId: string; financingId: string }, FoFFinancingRoundVO> = {
  summary: '获取轮次可编辑字段',
  method: 'get',
  url: `/api/v1/fof/projects/{projectId}/rounds/{financingId}/edit`,
  mock: false,
};

export const updateFofProjectRound: IConfig<{ projectId: string; financingId: string }, FoFFinancingRoundVO> = {
  summary: '更新轮次',
  method: 'put',
  url: `/api/v1/fof/projects/{projectId}/rounds/{financingId}`,
  mock: false,
};

export const getFofProjectDeals: IConfig<{ projectId: string; roundId: string }, FoFDealVO[]> = {
  summary: '获取项目轮次交易列表',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/rounds/{roundId}/deals',
  mock: false,
};

export const getFofProjectDealEdit: IConfig<{ projectId: string; dealId: string }, FoFFinancingRoundVO> = {
  summary: '获取交易可编辑字段',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/deals/{dealId}/edit',
  mock: false,
};

export const updateFofProjectDeal: IConfig<{ projectId: string; dealId: string }, any> = {
  summary: '更新交易',
  method: 'put',
  url: '/api/v1/fof/projects/{projectId}/deals/{dealId}',
  mock: false,
};

export const addFofProjectDeal: IConfig<{ projectId: string; roundId: string }, any> = {
  summary: '添加交易',
  method: 'post',
  url: '/api/v1/fof/projects/{projectId}/rounds/{roundId}/deals',
  mock: false,
};

export const deleteFofProjectDeal: IConfig<{ projectId: string; dealId: string }, any> = {
  summary: '删除交易',
  method: 'delete',
  url: '/api/v1/fof/projects/{projectId}/deals/{dealId}',
  mock: false,
};

export const getFofProjectWorkflowStage: IConfig<{ projectId: string; node: string; stage: string }, boolean> = {
  summary: '获取项目流程阶段',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/current/execution?node={node}&stage={stage}',
  mock: false,
};

export const getTransactionTypes: IConfig<{ isFirstInvested: number }, IOption[]> = {
  summary: '根据基金是否被投资过来获得当前基金可以选择的交易类型',
  method: 'get',
  url: '/api/v1/fof/deals/modes?isNew={isFirstInvested}&isListed=0',
  mock: false,
};

// ===================== 投后 ====================
export const getFofPortfolioFilterDataSource: IConfig<{ type?: FIELD_OPTION_KEY }, OptionObject[]> = {
  ...getFieldOption,
  summary: '拉取APP FOF投后筛选数据',
};

export const getFofPortfolioList: IConfig<{ columns?: string }, IPage<any>> = {
  summary: '获取FOF投后列表',
  method: 'get',
  url: '/api/v1/fof/funds/portfolios',
  mock: false,
};

export const getFofPortfolioDetail: IConfig<{ fundId?: string }, FundVO> = {
  ...getFofPipelineDetail,
  summary: '获取基金详情',
};
export const getFofPortfolioDetailEdit: IConfig<{ fundId?: string }, FundVO> = {
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/edit',
  mock: false,
  summary: '获取基金详情编辑字段',
};
export const updateFofPortfolioDetail: IConfig<{ fundId?: string }, FundVO> = {
  method: 'put',
  url: '/api/v1/fof/funds/{fundId}',
  mock: false,
  summary: '编辑基金详情',
};

export const getFofPortfolioMembers: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金成员',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/members',
  mock: false,
};

export const getFofPortfolioMembersEdit: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金成员编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/members/edit',
  mock: false,
};
export const updateFofPortfolioMembers: IConfig<{ fundId?: string }, any> = {
  summary: '保存基金成员',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/members',
  mock: false,
};

export const getFofPortfolioHistoryMembersEdit: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金历史成员编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/history-members/edit',
  mock: false,
};
export const updateFofPortfolioHistoryMembers: IConfig<{ fundId?: string }, any> = {
  summary: '保存基金历史成员',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/history-members',
  mock: false,
};

// 未实现价值
export const getFofPortfolioUnrealizedValues: IConfig<{ fundId?: string }, any[]> = {
  summary: '获取未实现价值列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/unrealized-values',
  mock: false,
};

export const deleteFofPortfolioUnrealizedValues: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '删除未实现价值',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}/unrealized-values/{id}',
  mock: false,
};

export const createFofPortfolioUnrealizedValues: IConfig<{ fundId?: string }, any> = {
  summary: '新增未实现价值',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/unrealized-values',
  mock: false,
};

export const updateFofPortfolioUnrealizedValues: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '更新未实现价值',
  method: 'put',
  url: '/api/v1/fof/funds/{fundId}/unrealized-values/{id}',
  mock: false,
};

export const getFofPortfolioUnrealizedValuesEdit: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '获取未实现价值编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/unrealized-values/{id}/edit',
  mock: false,
};
// call款
export const getFofPortfolioCalls: IConfig<{ fundId?: string }, any> = {
  summary: '[分页]获取Call款列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/calls',
  mock: false,
};

export const deleteFofPortfolioCalls: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '删除Call款',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}/calls/{id}',
  mock: false,
};

export const createFofPortfolioCalls: IConfig<{ fundId?: string }, any> = {
  summary: '新增Call款',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/calls',
  mock: false,
};

export const updateFofPortfolioCalls: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '更新call款',
  method: 'put',
  url: '/api/v1/fof/funds/{fundId}/calls/{id}',
  mock: false,
};

export const getFofPortfolioCallsEdit: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '获取call款编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/calls/{id}/edit',
  mock: false,
};

// 分红
export const getFofPortfolioDividends: IConfig<{ fundId?: string }, any> = {
  summary: '[分页]获取分红列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/dividends',
  mock: false,
};

export const deleteFofPortfolioDividends: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '删除分红',
  method: 'delete',
  url: '/api/v1/fof/funds/{fundId}/dividends/{id}',
  mock: false,
};
export const createFofPortfolioDividends: IConfig<{ fundId?: string }, any> = {
  summary: '新增分红',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/dividends',
  mock: false,
};

export const updateFofPortfolioDividends: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '更新分红',
  method: 'put',
  url: '/api/v1/fof/funds/{fundId}/dividends/{id}',
  mock: false,
};

export const getFofPortfolioDividendsEdit: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '获取分红编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/dividends/{id}/edit',
  mock: false,
};

export const getFofPortfolioCompany: IConfig<{}, any> = {
  summary: '[分页]搜索公司列表',
  method: 'get',
  url: '/api/v1/fof/funds/search-company',
  mock: false,
};

export const getFofPortfolioSummaries: IConfig<{ fundId?: string; currencyCode?: string }, any> = {
  summary: '查看基金腾讯投资概览',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/summaries',
  mock: false,
};

export const getFofPortfolioProgressProjects: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金执行项目列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/progress-projects',
  mock: false,
};

export const getFofPortfolioInvestmentHistory: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金历史项目列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/history-projects',
  mock: false,
};

export const getFofPortfolioPortfolios: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金Portfolio列表',
  method: 'get',
  url: '/api/v1/fof/funds/{fundId}/portfolios',
  mock: false,
};

export const getFofPortfolioCorporations: IConfig<{ fundId?: string }, any> = {
  summary: '获取基金关联主体信息',
  method: 'get',
  url: '/api/v1/fof/funds/corporations/{fundId}',
  mock: false,
};

export const updateFofPortfolioCorporations: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '编辑基金关联主体信息',
  method: 'put',
  url: '/api/v1/fof/funds/corporations/{fundId}/{id}',
  mock: false,
};

export const getFofPortfolioCorporationsEdit: IConfig<{ fundId?: string; id: string }, any> = {
  summary: '获取基金关联主体信息编辑字段',
  method: 'get',
  url: '/api/v1/fof/funds/corporations/{fundId}/{id}/edit',
  mock: false,
};

export const fofPortfolioImport: IConfig<any, any> = {
  summary: '导入基金Portfolio列表',
  method: 'post',
  url: '/api/v1/fof/funds/{fundId}/portfolios/import',
  mock: false,
};

export const investeeCorporationsByFundId: IConfig<{ fundId: string }, IPage<FoFProjectInvestorCorporationVO>> = {
  summary: '搜索对方主体',
  method: 'get',
  url: '/api/v1/investment/projects/investee-corporations?targetId={fundId}&keyword=&size=100',
  mock: false,
};

export const getDataVerifyApprovalDetail: IConfig<{ projectId: string }, FoFProjectLfDataVerifyApprovalVO> = {
  summary: '获取FOF数据审核审批详情',
  method: 'get',
  url: '/api/v1/fof/projects/{projectId}/workflows/lf/data-verify/approval',
  mock: false,
};

export const dataVerifyApproval: IConfig<{ projectId: string; executionId: string } & ApproveRequest, boolean> = {
  summary: 'FOF执行数据审核',
  method: 'post',
  url: '/api/v1/fof/projects/{projectId}/workflows/{executionId}/lf/data-verify/approval',
  mock: false,
};
