import { each, getConstructorOf, isInheritedOf, isFunction, isInstanceOf } from 'ts-fns';
import { Model } from 'tyshemo';
import { useEffect } from 'react';
import { Stream } from './stream';
import { SingleInstance } from './utils/utils';

export class Service extends SingleInstance {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __init() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Constructor = getConstructorOf(this);
    const streams = [];
    each(
      Constructor,
      (_, key) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const Item = Constructor[key];
        if (Item && isInheritedOf(Item, Service)) {
          this[key] = Item.instance();
        } else if (Item && isInheritedOf(Item, Model)) {
          this[key] = new Item();
        } else if (isFunction(Item) && key[key.length - 1] === '$') {
          const stream$ = new Stream();
          this[key] = stream$;
          streams.push([stream$, Item]);
        }
      },
      true,
    );
    // register all streams at last, so that you can call this.stream$ directly in each function.
    streams.forEach(([stream$, fn]) => fn.call(this, stream$));
  }
}
export default Service;

export function useService<T extends Service>(serv: new () => T): T {
  const service = (serv as any).instance();

  if (!isInstanceOf(service, Service)) {
    throw new Error(`useService 必须返回一个 Service 实例`);
  }

  useEffect(
    () => () => {
      service.destructor();
    },
    [],
  );

  return service;
}
