/**
 * 内嵌WebView的API拦截
 */

// 拦截器相关
type Interceptor = (...args: any) => Promise<any> | false;
const Interceptors: { [name: string]: Interceptor } = {
  default: null,
};

export function setInterceptor(name: string, interceptor: Interceptor) {
  Interceptors[name] = interceptor;
}

export function getInterceptor(name: string) {
  return Interceptors[name];
}
