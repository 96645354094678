import { importModule, Router, useRouteNavigate, useLocation } from '@core';
import { ConfigProvider, Loading } from 'tdesign-react';
import { Provider } from 'react-redux';
import { createWatermark } from '@src/components/watermark';
import { store, useDispatch, useSelector } from '@src/modules/tim-portal/store';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { tdConfig } from '@src/libs/i18n';
import { ExternalOutlet, InternalOutlet } from '@src/modules/tim-portal/index.module';
import Footer from './footer';
import Nav from './nav/nav';

import './app.less';

const ExternalOutletLogged = () => (
  <>
    <Nav type="external" />
    <ExternalOutlet />
    <Footer />
  </>
);

const InternalOutletLogged = () => (
  <>
    <Nav type="internal" />
    <InternalOutlet />
    <Footer />
  </>
);

const { Outlet: External } = new Router({
  routes: [
    {
      path: '',
      component: () => <ExternalOutletLogged />,
    },
    {
      path: 'login',
      component: importModule({
        source: () => import('@src/modules/tim-portal/login/index.module'),
      }),
    },
    {
      path: 'no-permission',
      component: importModule({
        source: () => import('@src/modules/tim-portal/no-permission/index.module'),
      }),
    },
  ],
});

const { Outlet: Internal } = new Router({
  routes: [
    {
      path: '',
      component: () => <InternalOutletLogged />,
    },
    {
      path: 'login',
      component: importModule({
        source: () => import('@src/modules/tim-portal/login/index.module'),
      }),
    },
    {
      path: 'no-permission',
      component: importModule({
        source: () => import('@src/modules/tim-portal/no-permission/index.module'),
      }),
    },
  ],
});

function Container() {
  const navigate = useRouteNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector();
  const [loading, setLoading] = useState(true);

  const redirectLogin = () => {
    if (!['/login', '/forgot-password', '/no-permission'].includes(pathname)) {
      navigate('/login', {}, 'replace');
    }
  };

  const redirectNoPermission = () => {
    navigate('/no-permission', {}, 'replace');
  };

  useEffect(() => {
    dispatch
      .getUserInfo()
      .then(
        (res) => {
          if (!res || isEmpty(res)) {
            redirectLogin();
            return;
          }
          // login页面跳到首页
          if (pathname === '/login') {
            navigate('/', {}, 'replace');
          }

          // 初始化水印
          createWatermark({
            content: `${res?.name} ${res?.nameEn} ${res?.id} ${new Date().toLocaleString()}`,
            parent: '#root',
          });
        },
        (err) => {
          if (err.code === 100900 && err.__environment === 'internal') {
            redirectNoPermission();
            return;
          }
          redirectLogin();
        },
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading fullscreen />;
  }
  return user?.type === 'internal' ? <Internal /> : <External />;
}

export default function App() {
  return (
    <Provider store={store}>
      <ConfigProvider globalConfig={tdConfig}>
        <Container />
      </ConfigProvider>
    </Provider>
  );
}
