import { importModule, Router } from '@core';
import { $i18next } from '@shared/i18n';

export const { Outlet: ExternalOutlet } = new Router({
  routes: [
    {
      path: '',
      component: importModule({
        source: () => import('@src/modules/tim-portal/home/index.module'),
      }),
      exact: true,
    },
    {
      path: 'change-password',
      component: importModule({
        source: () => import('@src/modules/tim-portal/change-password/index.module'),
      }),
    },
    {
      path: 'forgot-password',
      component: importModule({
        source: () => import('@src/modules/tim-portal/forgot-password/index.module'),
      }),
    },
    {
      path: 'data-entry/:id',
      component: importModule({
        source: () => import('@src/modules/tim-portal/data-entry/index.module'),
      }),
      exact: true,
    },
    {
      path: 'data-entry/:id/version/:versionId',
      component: importModule({
        source: () => import('@src/modules/tim-portal/version-detail/index.module'),
      }),
      exact: true,
    },
    {
      path: '!',
      component: importModule({
        source: () => import('@src/modules/tim-portal/not-found/index.module'),
      }),
      default: true,
    },
  ],
});

export const { Outlet: InternalOutlet } = new Router({
  routes: [
    {
      path: '',
      component: importModule({
        source: () => import('@src/modules/tim-portal/data-list/index.module'),
      }),
      exact: true,
    },
    {
      path: 'change-password',
      component: importModule({
        source: () => import('@src/modules/tim-portal/change-password/index.module'),
      }),
    },
    {
      path: 'forgot-password',
      component: importModule({
        source: () => import('@src/modules/tim-portal/forgot-password/index.module'),
      }),
    },
    {
      path: 'data-entry/:id',
      component: importModule({
        source: () => import('@src/modules/tim-portal/data-entry/index.module'),
      }),
      exact: true,
    },
    {
      path: 'data-entry/:id/version/:versionId',
      component: importModule({
        source: () => import('@src/modules/tim-portal/version-detail/index.module'),
      }),
      exact: true,
    },
    {
      path: '!',
      component: importModule({
        source: () => import('@src/modules/tim-portal/not-found/index.module'),
      }),
      default: true,
    },
  ],
});

export function navigator() {
  return [{ title: $i18next.t('tim-portal:db1c89e0:首页'), path: false }];
}
