/* eslint-disable @typescript-eslint/naming-convention */
import { each, getConstructorOf, isInheritedOf, isFunction, isInstanceOf, isObject, define, mixin } from 'ts-fns';
import { eject, Model } from 'tyshemo';
import type { ConstructorOf, AbstractConstructorOf } from '@shared/typings/utils';
import { memo, Component as ReactComponent, createContext } from 'react';
import { Store } from './store/store';
import { Component } from './component';
import { Stream } from './stream';
import { evolve } from './operators/operators';
import { Controller } from './controller';
import { Service } from './service';
import { DataService } from './services/data-service';
import { PrimitiveBase, ofChainStatic } from './utils/utils';

const PersistentItems = Symbol();
const PersistentContext = createContext({ presists: [] });

/**
 * class SomeView extends View {
 *   controller = new SomeController()
 *
 *   VoteButton(props) {
 *     return <Button onHit={this.controller.vote$}>Vote</Button>
 *   }
 *
 *   VoteCount(props) {
 *     return <Text>{this.controller.vote.count}</Text>
 *   }
 * }
 */
export class View<P = any, S = any> extends Component<P, S> {
  @eject() observers: { observer: any; type: 'onlythis' | 'shared' }[];

  static get contextType() {
    return PersistentContext;
  }

  __provide() {
    const components = [];
    const observers = [];

    const patchIns = (key, Con, items) => {
      const item = items.find((item) => item.Con === Con);
      if (item) {
        const { ins } = item;
        if (ins) {
          this[key] = ins;
        } else {
          const ins = new Con();
          this[key] = ins;
          item.ins = ins;
        }
        observers.push({ observer: this[key], type: 'shared' });
      } else {
        this[key] = new Con();
        observers.push({ observer: this[key] });
      }
    };

    const Constructor = getConstructorOf(this);
    const streams = [];
    const staticProperties = ofChainStatic(Constructor, View);

    each(
      staticProperties,
      (_, key) => {
        const Item = Constructor[key];
        if (Item && isInheritedOf(Item, DataService)) {
          this[key] = Item.instance();
          // notice that, any data source change will trigger the rerender
          // so you should must pass collect to determine when to rerender, look into example of `reactive`
          observers.push({ observer: this[key] });
        } else if (Item && isInheritedOf(Item, Service)) {
          this[key] = Item.instance();
        } else if (Item && isInheritedOf(Item, Model)) {
          this[key] = new Item();
          observers.push({
            observer: {
              subscribe: (dispatch) => {
                this[key].watch('*', dispatch, true);
                this[key].watch('!', dispatch, true);
                this[key].on('recover', dispatch, true);
              },
              unsubscribe: (dispatch) => {
                this[key].unwatch('*', dispatch);
                this[key].unwatch('!', dispatch);
                this[key].off('recover', dispatch);
              },
            },
          });
        } else if (Item && (isInheritedOf(Item, Controller) || Item === Store || isInheritedOf(Item, Store))) {
          // inherit from upper components
          if (this.context?.presists?.length) {
            patchIns(key, Item, this.context.presists);
          }
          // self is top
          else if (Constructor[PersistentItems]) {
            patchIns(key, Item, Constructor[PersistentItems]);
          }
          // only use this controller
          else {
            this[key] = new Item();
            observers.push({ observer: this[key] });
          }
        } else if (isFunction(Item) && key[key.length - 1] === '$') {
          const stream$ = new Stream();
          this[key] = stream$;
          streams.push([Item, stream$]);
        } else if (isFunction(Item) || isInstanceOf(Item, ReactComponent)) {
          const charCode = key.charCodeAt(0);
          // if not uppercase, make it as a method
          if (charCode >= 65 && charCode <= 90) {
            const C = isInstanceOf(Item, ReactComponent) ? Item : Item.bind(this);
            components.push([key, C]);
          }
        }
      },
      true,
    );

    // subscribe to observers
    // should must before components registering
    this.observers = observers;

    // register all streams at last, so that you can call this.stream$ directly in each function.
    streams.forEach(([fn, stream$]) => fn.call(this, stream$));

    // register components
    components.forEach(([key, C]) => {
      this[key] = this.reactive(C);
    });

    each(this, (value, key) => {
      if (isObject(value) && value.$$type === 'reactive' && value.component) {
        this[key] = this.reactive(
          value.component,
          typeof value.collect === 'function' ? value.collect.bind(this) : null,
        );
      }
    });
  }

  /**
   *
   * @param {*} component
   * @param {function|array} collect
   * function: collect passed into evovle;
   * array: data sources which to subscribe;
   * @returns
   * @example
   *
   * this.reactive(
   *   () => {
   *     const some = this.controller.dataService.get('some')
   *     return <span>{some.name}</span>
   *   },
   *   () => {
   *     const some = this.controller.dataService.get('some')
   *     return [some]
   *   },
   * )
   */
  reactive<P>(component, collect?) {
    if (!this.update) {
      return {
        $$type: 'reactive',
        component,
        collect,
      };
    }

    const E = collect ? evolve(collect)(component) : component;

    const { observers } = this;
    class G extends Component<P> {
      onMounted() {
        observers.forEach(({ observer, type }) => {
          if (type === 'onlythis') {
            return;
          }
          observer.subscribe(this.weakUpdate);
        });
      }
      onUnmount() {
        observers.forEach(({ observer, type }) => {
          if (type === 'onlythis') {
            return;
          }
          observer.unsubscribe(this.weakUpdate);
        });
      }
      render() {
        const attrs = { ...this.props } as any;
        delete attrs.stylesheet;
        return <E {...attrs} className={this.className} style={this.style} />;
      }
    }

    return memo(G);
  }

  componentDidMount() {
    this.observers.forEach(({ observer }) => {
      observer.subscribe(this.weakUpdate);
    });
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.observers.forEach(({ observer, type }) => {
      observer.unsubscribe(this.weakUpdate);
      // destroy single instances
      if (isInstanceOf(observer, PrimitiveBase) && type !== 'shared') {
        observer.destructor();
      }
    });
    this.observers.length = 0;
  }

  /**
   * observe actions only works for current component, not for inner component
   * @param {*} observer
   */
  observe(source) {
    let observer = source;
    if (isInstanceOf(source, Model)) {
      observer = {
        subscribe: (dispatch) => {
          source.watch('*', dispatch, true);
          source.watch('!', dispatch, true);
          source.on('recover', dispatch, true);
        },
        unsubscribe: (dispatch) => {
          source.unwatch('*', dispatch);
          source.unwatch('!', dispatch);
          source.off('recover', dispatch);
        },
      };
    }

    if (this._isMounted) {
      observer.subscribe(this.weakUpdate);
    }
    if (!this._isUnmounted) {
      this.observers.push({ observer, type: 'onlythis' });
    }
  }

  disobserve(observer) {
    const index = this.observers.findIndex((item) => item.observer === observer);
    if (index === -1) {
      return;
    }

    observer.unsubscribe(this.weakUpdate);
    // destroy single instances
    if (isInstanceOf(observer, PrimitiveBase)) {
      observer.destructor();
    }
    this.observers.splice(index, 1);
  }

  /**
   * create a View which share Controller inside
   * @param {*} Controller
   * @returns
   * @examples
   * // 第一步，声明一个内部共存Controller的View
   * // 一般View都是abstract class，需要被具体组件实现
   * abstract class SomeView extends View.Persist([SomeController]) {}
   * // 第二步，实现该View的组件
   * class SomeComponent extends SomeView {}
   * // 第三步，在其他组件中使用该组件
   * function AnyComponent() {
   *    const controller = useController(SomeController, SomeComponent)
   *    return <SomeComponent />
   * }
   */
  static Persist<T extends View>(
    this: ConstructorOf<T>,
    Cons: (new () => Controller | Store)[],
  ): typeof View & ConstructorOf<T> {
    const patchedPersistentItems = this[PersistentItems] || [];
    const initPersistentItems = [...patchedPersistentItems];
    Cons.forEach((Con) => {
      if (initPersistentItems.some((item) => item.Con === Con)) {
        return;
      }
      initPersistentItems.push({ Con });
    });

    // @ts-ignore
    return class extends this {
      static [PersistentItems] = initPersistentItems;

      __init() {
        super.__init();
        const Constrcutor = getConstructorOf(this);
        const patchedPersistentItems = Constrcutor[PersistentItems];

        const render = this.render.bind(this);
        const persisRender = () => {
          const { Provider, Consumer } = PersistentContext;
          return (
            <Consumer>
              {(context) => {
                const hasPresists = !context.presists?.length;
                const presists = (context.presists || []).map((item) => Object.create(item));

                patchedPersistentItems.forEach((item) => {
                  const { Con } = item;
                  if (presists.some((item) => item.Con === Con)) {
                    return;
                  }
                  presists.push(item);
                });

                if (!hasPresists) {
                  // eslint-disable-next-line no-param-reassign
                  context.presists = presists;
                }

                return <Provider value={{ ...context, presists }}>{render()}</Provider>;
              }}
            </Consumer>
          );
        };
        define(this, 'render', { value: persisRender, configurable: true });
      }

      componentWillUnmount() {
        const Constrcutor = getConstructorOf(this);
        const patchedPersistentItems = Constrcutor[PersistentItems];
        // 必须先执行，然后再进入上面的componentWillUnmount
        [...(this.context?.presists || []), ...patchedPersistentItems].forEach((item) => {
          const { ins } = item;
          // eslint-disable-next-line no-param-reassign
          delete item.ins;
          // 如果正好从原型链上删除拉实例，那么该实例要执行destructor来销毁一些监听逻辑，释放内存
          if (!item.ins && isInstanceOf(ins, PrimitiveBase)) {
            ins.destructor();
          }
        });
        super.componentWillUnmount();
      }
    };
  }

  /**
   * 声明两个View的子集
   *
    abstract class A extends View {
      say() {}
      run() {}
    }

    abstract class B extends View {
      say() {}
      cry() {}
    }

    // 定义一个C，这个C内部可以使用A和B共有的成员
    // 但是需要注意，C中只能实现方法，不可以实现成员
    abstract class C extends View.Embed<A | B>() {}

    // 具体实现D，D首先是A，其次是C
    // D同时具备了A和C，即A和C的组合体
    class D extends C.implement(A) {}

    const d = new D();
    d.say();

  * 上面这个例子中，我们有A和B两个View，这两个View实际上用了相同的C来进行界面的渲染
  * 基于这一设计，我们可以将交互逻辑View和UI渲染C进行分离，A和B虽然都对应C，但在最终呈现出界面和交互时，由A或B自身的逻辑决定，即把C这件衣服给A或B穿，将呈现完全不同的效果
  * @returns
  */
  static Embed<T extends View>(): {
    Adopt<THIS, V extends ConstructorOf<View> | AbstractConstructorOf<View>>(
      this: ConstructorOf<THIS>,
      Proto: V,
    ): V extends abstract new (props: infer P) => View
      ? new (props: P) => View<P>
      : V extends new (props: infer Q) => View
      ? new (props: Q) => View<Q>
      : never;
  } & (new (...args: any[]) => View & Omit<T[keyof T], keyof View>) &
    typeof View {
    // @ts-ignore
    return class EmbeddedView extends View {
      static Adopt(Proto) {
        class Sub extends Proto {}
        mixin(Sub, this);
        // @ts-ignore
        Sub.prototype.$$hooks = this.prototype.$$hooks;
        // @ts-ignore
        return Sub;
      }
    };
  }
}
