/**
 * investment模块接口定义
 * * 建议模块分类按照后端接口分类来 比较好找
 */
import type {
  AppointmentVO,
  Page,
  CompanyExecutiveVO,
  TeamEvaluationVO,
  FinancingRoundVO,
  ProjectMemberVO,
  DealModeFieldVO,
  UpdateProjectRequest,
  CompanyVO,
  CompanyMemberVO,
  CompanyProcessingProjectVO,
  CompanyCorporationVO,
  FinancingOverviewVO,
  InvestmentOverviewVO,
  CorporationListingVO,
  CorporationListingHoldingShareVO,
  IPage,
  CreateCorporationListingHoldingShareRequest,
  CorporationListingDrRatioVO,
  InvestmentProjectVO,
  ProjectLfDataVerifyApprovalVO,
  ApproveRequest,
  CorporationRevaluationVO,
  CorporationWhetherDevalueVO,
} from '@shared/api/interface';
import type {
  ListDataQueryParamsType,
  TableRecordType as CompaniesResponseType,
} from '@shared/typings/components/list';
import type { IOption, IOptions } from '@shared/typings/option';
import type { IPagination } from '@shared/typings/pagination';
import type { IConfig } from './types';

export const getCompanyById: IConfig<{ companyId?: string }, CompanyVO> = {
  summary: '获取公司详情',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}',
  mock: false,
  request: {},
  response: {},
};

export const deleteCompanyById: IConfig<{ companyId?: string }, boolean> = {
  summary: '删除公司',
  method: 'delete',
  url: '/api/v1/investment/companies/{companyId}',
  mock: false,
  request: {},
  response: true,
};

export const followCompanyById: IConfig<{ companyId?: string }, boolean> = {
  summary: '关注公司',
  method: 'post',
  url: '/api/v1/investment/companies/{companyId}/follows',
  mock: false,
  request: {},
  response: true,
};

export const unfollowCompanyById: IConfig<{ companyId?: string }, boolean> = {
  summary: '取消关注公司',
  method: 'delete',
  url: '/api/v1/investment/companies/{companyId}/follows',
  mock: false,
  request: {},
  response: true,
};

export const getCompanies: IConfig<
  {
    code: 'pipelines' | 'portfolio';
  } & ListDataQueryParamsType,
  CompaniesResponseType
> = {
  url: '/api/v1/investment/companies/{code}',
  method: 'get',
  summary: '获取公司接口',
};

export const getKickoffProjects: IConfig<
  ListDataQueryParamsType,
  IPagination & {
    records: any[];
  }
> = {
  url: '/api/v1/investment/projects/kickoffs',
  method: 'get',
  summary: '获取投中项目列表',
};

export const getPortfolioCompanies: IConfig<
  ListDataQueryParamsType,
  IPagination & {
    records: any[];
  }
> = {
  url: '/api/v1/investment/companies/portfolios',
  method: 'get',
  summary: '获取投后公司列表',
};

export const getPipelineFilterDataSource: IConfig<
  {
    type: ['PROFESSION', 'STAR', 'ROUND'];
  },
  {
    PROFESSION: IOptions;
    STAR: IOptions;
    ROUND: IOptions;
  }
> = {
  url: '/api/v1/common/options',
  request: {
    type: ['PROFESSION', 'STAR', 'ROUND'],
  },
  method: 'get',
  summary: '拉取APP投前筛选数据',
};

export const getKickoffFilterDataSource: IConfig<
  {
    type: ['PROFESSION', 'DEAL_MODE', 'SPONSOR', 'FAST_PROCEDURE', 'PROJECT_STATUS'];
  },
  {
    DEAL_MODE: IOptions;
    SPONSOR: IOptions;
    FAST_PROCEDURE: IOptions;
    PROJECT_STATUS: IOptions;
    PROFESSION: IOptions;
  }
> = {
  url: '/api/v1/common/options',
  method: 'get',
  summary: '拉取APP投中筛选数据',
};

export const getPortfolioFilterDataSource: IConfig<
  {
    type: ['REGION_ZONE', 'PROFESSION', 'ROUND', 'SPONSOR', 'CLASSIFICATION'];
  },
  {
    REGION_ZONE: IOptions;
    SPONSOR: IOptions;
    ROUND: IOptions;
    PROFESSION: IOptions;
    CLASSIFICATION: IOptions;
  }
> = {
  url: '/api/v1/common/options',
  method: 'get',
  summary: '拉取APP投后筛选数据',
};

export const getCompanyAppointmentsList: IConfig<{ companyId?: string; id?: string }, Page<AppointmentVO>> = {
  summary: '获取公司约见列表',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/appointments',
  mock: false,
  response: {},
};

export const getCompanyAppointmentsItem: IConfig<{ companyId?: string; id?: string }, AppointmentVO> = {
  summary: '获取公司约见详情',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/appointments/{id}',
  mock: false,
  response: {},
};

export const deleteCompanyAppointmentsItem: IConfig<{ companyId?: string; id?: string }, any> = {
  summary: '删除公司约见记录',
  method: 'delete',
  url: '/api/v1/investment/companies/{companyId}/appointments/{id}',
  mock: false,
};

export const getFinanceData: IConfig<{ companyId?: string }, any> = {
  summary: '融资历史',
  method: 'get',
  url: '/api/v1/investment/external/companies/{companyId}/investments',
  mock: false,
  response: {},
};

export const getMembersData: IConfig<{ companyId?: string }, any> = {
  summary: '团队成员',
  method: 'get',
  url: '/api/v1/investment/external/companies/{companyId}/members',
  mock: false,
  response: {},
};

export const getCompanyExecutives: IConfig<{ companyId: string }, CompanyExecutiveVO[]> = {
  summary: '获取公司高管信息',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/company-executives',
};

export const deleteCompanyExecutives: IConfig<{ id: string; companyId: string }, boolean> = {
  summary: '删除高管信息',
  method: 'delete',
  url: '/api/v1/investment/companies/{companyId}/company-executives/{id}',
};

export const createCompanyExecutives: IConfig<{ companyId: string }, Required<CompanyExecutiveVO>> = {
  summary: '新建公司高管信息',
  method: 'POST',
  url: '/api/v1/investment/companies/{_companyId}/company-executives',
};

export const updateCompanyExecutives: IConfig<CompanyExecutiveVO, Required<CompanyExecutiveVO>> = {
  summary: '编辑高管信息',
  method: 'PUT',
  url: '/api/v1/investment/companies/{companyId}/company-executives/{id}',
};

export const getProjectById: IConfig<{ projectId: string }, Required<InvestmentProjectVO>> = {
  summary: '获取项目详情',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}',
};

export const getProjectFinancingRounds: IConfig<{ projectId: string }, Required<FinancingRoundVO[]>> = {
  summary: '获取项目的融资轮次列表',
  method: 'GET',
  url: '/api/v1/investment/projects/{projectId}/financing-rounds',
};

export const getTeamEvaluations: IConfig<
  {
    projectId: string;
    companyId?: string;
  },
  Required<TeamEvaluationVO>[]
> = {
  summary: '获取团队服务评价',
  method: 'get',
  url: '/api/v1/investment/projects/{projectId}/team-evaluations',
};

export const createTeamEvaluations: IConfig<TeamEvaluationVO, Required<TeamEvaluationVO>> = {
  summary: '新增团队服务评价',
  method: 'post',
  url: '/api/v1/investment/projects/{projectId}/team-evaluations',
};

export const updateTeamEvaluations: IConfig<Required<TeamEvaluationVO>, Required<TeamEvaluationVO>> = {
  summary: '修改团队服务评价',
  method: 'put',
  url: '/api/v1/investment/projects/team-evaluations/{id}',
};

export const deleteTeamEvaluations: IConfig<{ id: string }, boolean> = {
  summary: '删除团队评价',
  method: 'delete',
  url: '/api/v1/investment/projects/team-evaluations/{id}',
};

export const getMembers: IConfig<{ projectId: string }, ProjectMemberVO[]> = {
  summary: '获取投中成员',
  method: 'get',
  url: '/api/v1/investment/projects/{projectId}/members',
};

export const updateMembers: IConfig<{ projectId: string }, Required<ProjectMemberVO>> = {
  summary: '编辑投中成员',
  method: 'post',
  url: '/api/v1/investment/projects/{projectId}/members',
};

export const getDealsModesFields: IConfig<{ code: string }, DealModeFieldVO[]> = {
  summary: '获取交易类型对应字段',
  method: 'get',
  url: `/api/v1/investment/deals/modes/{code}/fields`,
};

export const updateProjectDetail: IConfig<{ projectId: string } & UpdateProjectRequest, any> = {
  summary: '编辑项目详情',
  method: 'put',
  url: '/api/v1/investment/projects/{projectId}',
};

export const getFinancingRoundsHistory: IConfig<{ companyId: string }, any> = {
  summary: '投后获取公司不同轮次的投资历史',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/historical-projects`,
};

export const exportFinancingRoundsHistory: IConfig<{ companyId: string }, any> = {
  summary: '投后腾讯投资历史导出',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/historical-projects/export`,
};

export const getFinancingSubject: IConfig<{ companyId: string }, any> = {
  summary: '投后财务管理获取对应公司主体',
  method: 'get',
  url: `/api/v1/investment/companies/financial/{companyId}/corporations`,
};

export const getFinancingSplitmergesharedeals: IConfig<{ companyId: string; corporationId?: string }, any> = {
  summary: '投后财务管理获取拆并股列表信息',
  method: 'get',
  url: `/api/v1/investment/companies/financial/{companyId}/split-merge-shares`,
};

export const deleteFinancingSplitmergesharedeals: IConfig<{ companyId: string; dealId: string }, any> = {
  summary: '投后财务管理-删除拆并股信息',
  method: 'delete',
  url: '/api/v1/investment/companies/financial/corporation/{companyId}/split-merge-share/{dealId}',
};

export const getFinancingRevaluations: IConfig<
  { companyId: string; corporationId?: string },
  IPage<CorporationRevaluationVO>
> = {
  summary: '投后财务管理获取估值调整列表信息',
  method: 'get',
  url: `/api/v1/investment/companies/financial/{companyId}/revaluations`,
};

export const deleteFinancingRevaluations: IConfig<{ companyId: string; dealId: string }, any> = {
  summary: '投后财务管理-删除估值调整信息',
  method: 'delete',
  url: `/api/v1/investment/companies/financial/corporation/{companyId}/revaluation/{dealId}`,
};

export const getFinancingDividends: IConfig<{ companyId: string; corporationId?: string }, any> = {
  summary: '投后财务管理获取分红列表信息',
  method: 'get',
  url: `/api/v1/investment/companies/financial/{companyId}/dividends`,
};

export const deleteFinancingDividends: IConfig<{ companyId: string; dealId: string }, any> = {
  summary: '投后财务管理-删除分红信息',
  method: 'delete',
  url: `/api/v1/investment/companies/financial/corporation/{companyId}/dividend/{dealId}`,
};

export const getCompanyEdit: IConfig<{ companyId: string }, any> = {
  summary: '获取公司编辑的数据',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/edit',
};

export const updateCompany: IConfig<{ companyId: string }, any> = {
  summary: '更新公司数据',
  method: 'PUT',
  url: '/api/v1/investment/companies/{companyId}',
};

export const getCompanyProcessingProjects: IConfig<{ companyId: string }, CompanyProcessingProjectVO[]> = {
  summary: '公司执行中的项目',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/processing-projects',
};

export const getCompanyMembers: IConfig<{ companyId: string }, CompanyMemberVO> = {
  summary: '获取公司成员',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/members`,
};

export const getCompanyMembersEdit: IConfig<{ companyId: string }, CompanyMemberVO> = {
  summary: '获取公司成员编辑信息',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/members/edit`,
};

export const updateCompanyMembers: IConfig<{ companyId: string }, any> = {
  summary: '更新公司成员',
  method: 'post',
  url: `/api/v1/investment/companies/{companyId}/members`,
};

export const getCompanyHistoryMembersEdit: IConfig<{ companyId: string }, any> = {
  summary: '获取公司历史成员编辑信息',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/history-members/edit`,
};

export const updateCompanyHistoryMembers: IConfig<{ companyId: string }, any> = {
  summary: '更新公司历史成员',
  method: 'post',
  url: `/api/v1/investment/companies/{companyId}/history-members`,
};

export const getCompanyCorporations: IConfig<{ companyId: string }, CompanyCorporationVO[]> = {
  summary: '获取公司主体列表',
  method: 'get',
  url: `/api/v1/investment/companies/portfolios/corporations/{companyId}/`,
};

export const getCompanyCorporationsEdit: IConfig<{ companyId: string; investeeId: string }, CompanyCorporationVO> = {
  summary: '获取公司主体的编辑信息',
  method: 'get',
  url: `/api/v1/investment/companies/portfolios/corporations/{companyId}/{investeeId}/edit`,
};

export const updateCompanyCorporation: IConfig<{ companyId: string; investeeId: string }, CompanyCorporationVO> = {
  summary: '编辑公司主体信息',
  method: 'put',
  url: `/api/v1/investment/companies/portfolios/corporations/{companyId}/{investeeId}`,
};

export const getCompanyFinancingOverviews: IConfig<{ companyId: string }, FinancingOverviewVO[]> = {
  summary: '腾讯投资概览列表',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/financing-overviews`,
};

export const getCompanyTencentInvestmentOverviews: IConfig<
  { companyId: string; currencyCode?: string },
  InvestmentOverviewVO[]
> = {
  summary: '腾讯投资概览，要先设置统计口径',
  method: 'get',
  url: `/api/v1/investment/companies/{companyId}/tencent-investment-overviews?currencyCode={currencyCode}`,
};

export const createCompanyCorporationListing: IConfig<{ corporationId: string }, InvestmentOverviewVO[]> = {
  summary: '创建主体上市信息',
  method: 'post',
  url: `/api/v1/investment/companies/corporation/{corporationId}/listing`,
};

export const getCompanyCorporationListing: IConfig<
  { corporationId: string; corporationListingId: string },
  CorporationListingVO
> = {
  summary: '获取公司主体上市主体信息',
  method: 'get',
  url: `/api/v1/investment/companies/corporation/{corporationId}/listing/{corporationListingId}`,
};

export const updateCompanyCorporationListing: IConfig<
  { corporationId: string; listingId: string },
  InvestmentOverviewVO[]
> = {
  summary: '更新主体上市信息',
  method: 'put',
  url: `/api/v1/investment/companies/corporation/{corporationId}/listing/{listingId}`,
};

export const delistingCompanyCorporationListing: IConfig<
  { corporationId: string; listingId: string; eventDate: string },
  InvestmentOverviewVO[]
> = {
  summary: '退市主体上市信息',
  method: 'put',
  url: `/api/v1/investment/companies/corporation/{corporationId}/listing/{listingId}/delisting?eventDate={eventDate}`,
};

export const deleteCompanyCorporationListing: IConfig<
  { corporationId: string; listingId: string },
  InvestmentOverviewVO[]
> = {
  summary: '删除主体上市信息',
  method: 'delete',
  url: `/api/v1/investment/companies/corporation/{corporationId}/listing/{listingId}`,
};

export const updateCompanySummaries: IConfig<
  { companyId: string; statisticalCaliber: string },
  InvestmentOverviewVO[]
> = {
  summary: '更新主体上市信息',
  method: 'put',
  url: `/api/v1/investment/companies/{companyId}/summaries`,
};

export const getTransactionTypes: IConfig<{ isListed: number; isInvested: number; isSupply?: number }, IOption[]> = {
  summary: '根据基金是否被投资过来获得当前基金可以选择的交易类型',
  method: 'get',
  url: '/api/v1/investment/deals/modes?isListed={isListed}&isNew={isInvested}&isSupplemented={isSupply}',
  mock: false,
};

export const addCompanyListingHoldingShare: IConfig<
  { corporationId: string } & CreateCorporationListingHoldingShareRequest,
  IPage<CorporationListingHoldingShareVO>
> = {
  summary: '新增主体上市持有股数历史纪录',
  method: 'post',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/holdingShare',
  mock: false,
};

export const updateCompanyListingHoldingShare: IConfig<
  { corporationId: string } & CreateCorporationListingHoldingShareRequest,
  IPage<CorporationListingHoldingShareVO>
> = {
  summary: '更新主体上市持有股数历史纪录',
  method: 'put',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/holdingShare',
  mock: false,
};

export const getCompanyListingHoldingShare: IConfig<
  { corporationId: string },
  IPage<CorporationListingHoldingShareVO>
> = {
  summary: '获取主体上市持有股数历史纪录',
  method: 'get',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/holdingShare',
  mock: false,
};

export const deleteCompanyListingHoldingShare: IConfig<{ corporationId: string; id: string }, boolean> = {
  summary: '删除主体上市holdingShare历史记录',
  method: 'delete',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/holdingShare/{id}',
  mock: false,
};

export const addCompanyListingDrRatio: IConfig<
  { corporationId: string } & CreateCorporationListingHoldingShareRequest,
  IPage<CorporationListingHoldingShareVO>
> = {
  summary: '新增主体上市DrRatio历史记录',
  method: 'post',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/drRatio',
  mock: false,
};

export const updateCompanyListingDrRatio: IConfig<
  { corporationId: string } & CreateCorporationListingHoldingShareRequest,
  IPage<CorporationListingHoldingShareVO>
> = {
  summary: '编辑主体上市DrRatio历史记录',
  method: 'put',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/drRatio',
  mock: false,
};

export const getCompanyListingDrRatio: IConfig<{ corporationId: string }, IPage<CorporationListingDrRatioVO>> = {
  summary: '获取主体上市DrRatio',
  method: 'get',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/drRatio',
  mock: false,
};

export const deleteCompanyListingDrRatio: IConfig<{ corporationId: string; id: string }, boolean> = {
  summary: '删除主体上市drRatio历史记录',
  method: 'delete',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/drRatio/{id}',
  mock: false,
};

export const checkCompanyListingDrRatioDate: IConfig<{ corporationId: string }, boolean> = {
  summary: '检查主体上市drRatio历史记录是否重复',
  method: 'get',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/drRatio/date',
  mock: false,
};

export const checkCompanyListingHoldingSharedDate: IConfig<{ corporationId: string }, boolean> = {
  summary: '检查主体上市drRatio历史记录是否重复',
  method: 'get',
  url: '/api/v1/investment/companies/corporation/{corporationId}/listing/holdingShare/date',
  mock: false,
};

export const getDataVerifyApprovalDetail: IConfig<{ projectId: string }, ProjectLfDataVerifyApprovalVO> = {
  summary: '获取数据审核审批详情',
  method: 'get',
  url: '/api/v1/investment/projects/{projectId}/workflows/lf/data-verify/approval',
  mock: false,
};

export const dataVerifyApproval: IConfig<{ projectId: string; executionId: string } & ApproveRequest, boolean> = {
  summary: '执行数据审核',
  method: 'post',
  url: '/api/v1/investment/projects/{projectId}/workflows/{executionId}/lf/data-verify/approval',
  mock: false,
};

export const getTppFollow: IConfig<{ companyId?: string }, any> = {
  summary: '获取公司TPP是否跟投',
  method: 'get',
  url: `/api/v1/investment/companies/check-tpp-follow?org_id={companyId}`,
  mock: false,
  request: {},
  response: {},
};

export const getProjectTppFollow: IConfig<{ projectId?: string }, any> = {
  summary: '获取项目TPP是否跟投',
  method: 'get',
  url: `/api/v1/investment/companies/check-tpp-follow?tim_id={projectId}`,
  mock: false,
  request: {},
  response: {},
};

export const getCompaniesCorporationWhetherDevalue: IConfig<{ companyId: string }, CorporationWhetherDevalueVO[]> = {
  summary: '获取公司下的主体是否进行减值操作',
  method: 'get',
  url: '/api/v1/investment/companies/{companyId}/corporation-whether-devalue',
  mock: false,
};
