/*
 * @Author: v_zhiwwliu
 * @Date: 2022-10-14 15:17:16
 * @LastEditors: v_zhiwwliu
 * @LastEditTime: 2022-10-24 18:35:39
 * @Description: 全局搜索接口定义
 */

import type { IConfig } from './types';

export const previewSearch: IConfig<{ keyword: string }, any> = {
  summary: '全局搜索预览接口',
  method: 'get',
  url: '/api/v1/search/preview-search',
  mock: false,
};

export const commonSearch: IConfig<{ keyword: string; scope?: string }, any> = {
  summary: '综合搜索接口',
  method: 'get',
  url: '/api/v1/search/common-search',
  mock: false,
};

export const pipelinesSearch: IConfig<any, any> = {
  summary: '直投pipeline搜索接口',
  method: 'get',
  url: '/api/v1/search/pipelines',
  mock: false,
};

export const portfoliosSearch: IConfig<any, any> = {
  summary: '直投portfolios搜索接口',
  method: 'get',
  url: '/api/v1/search/portfolios',
  mock: false,
};

export const kickoffsSearch: IConfig<any, any> = {
  summary: '直投kickoffs搜索接口',
  method: 'get',
  url: '/api/v1/search/kickoffs',
  mock: false,
};

export const fofPipelinesSearch: IConfig<any, any> = {
  summary: 'fof-pipeline搜索接口',
  method: 'get',
  url: '/api/v1/search/fof/pipelines',
  mock: false,
};

export const fofFickoffsSearch: IConfig<any, any> = {
  summary: 'fof-kickoffs搜索接口',
  method: 'get',
  url: '/api/v1/search/fof/kickoffs',
  mock: false,
};

export const fofPortfoliosSearch: IConfig<any, any> = {
  summary: 'fof-portfolios搜索接口',
  method: 'get',
  url: '/api/v1/search/fof/portfolios',
  mock: false,
};

export const otherCorporationsSearch: IConfig<any, any> = {
  summary: '其他主体搜索接口',
  method: 'get',
  url: '/api/v1/search/other-corporations',
  mock: false,
};

export const tencentCorporationsSearch: IConfig<any, any> = {
  summary: '腾讯主体搜索接口',
  method: 'get',
  url: '/api/v1/search/tencent-corporations',
  mock: false,
};

export const paymentsSearch: IConfig<any, any> = {
  summary: '付款搜索接口',
  method: 'get',
  url: '/api/v1/search/payments',
  mock: false,
};

export const signsSearch: IConfig<any, any> = {
  summary: '签署搜索接口',
  method: 'get',
  url: '/api/v1/search/signs',
  mock: false,
};

export const knowledgebaseSearch: IConfig<any, any> = {
  summary: '知识库搜索接口',
  method: 'get',
  url: '/api/v1/search/knowledgebase',
  mock: false,
};

export const gpsSearch: IConfig<any, any> = {
  summary: 'gp搜索接口',
  method: 'get',
  url: '/api/v1/search/gps',
  mock: false,
};

export const clauseSearch: IConfig<any, any> = {
  summary: '重要条款搜索接口',
  method: 'get',
  url: '/api/v1/search/clauses',
  mock: false,
};

export const disclosureSearch: IConfig<any, any> = {
  summary: '披露管理搜索接口',
  method: 'get',
  url: '/api/v1/search/disclosures',
  mock: false,
}

export const legaldatabasesSearch:IConfig<any, any> = {
  summary: '基金legal database搜索接口',
  method: 'get',
  url: '/api/v1/search/legaldatabases',
  mock: false,
};
