/**
 * 项目环境
 */
export enum ProjectEnvEnum {
  Dev = 'dev',
  Test = 'test',
  Pre = 'pre',
  Prod = 'prod',
}

export type ProjectEnvRecordItem = {
  /**
   * Web根域
   */
  Web: string;
  /**
   * 移动网关WOA地址，存在移动端项目才需要配置
   */
  App?: string;
  /**
   * 移动网关oa.m.tencent.com地址，存在移动端项目才需要配置
   */
  AppGateway?: string;
  // WechatAppId: string
  /**
   * 外网站点需要的加密key
   */
  EncryptKey?: string;
};

/**
 * 项目环境对应的配置定义
 */
export type ProjectEnvRecord = Record<ProjectEnvEnum, ProjectEnvRecordItem>;

/**
 * 项目定义
 */
export enum ProjectNameEnum {
  TIM = 'TIM',
  TPP = 'TPP',
  ADMIN = 'ADMIN', // 大后台
  CLAUSE = 'CLAUSE', // 条款
  TIM_PORTAL = 'TIM_PORTAL', // 被投门户
  SCREENSHOT = 'SCREENSHOT', // 截图服务
}

/**
 * 全局配置
 */
export interface GlobalConfig {
  projectName: ProjectNameEnum;
  projectEnv: ProjectEnvRecord;
  aegisIdPc: string;
  aegisIdApp: string;
}
