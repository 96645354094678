import { FIELD_OPTION_KEY, SETTING_KEY } from '@shared/constants/server-hard-code';
import type { UserMeVO, OptionObject, IPage, Tag, DatasetVO, DatasetViewVO, SaveViewRequest } from '../interface/index';
import type { IConfig } from './types';

export const getUser: IConfig<{}, UserMeVO> = {
  summary: '获取当前登录用户',
  method: 'get',
  url: '/api/v1/common/users/me',
  mock: false,
  request: {},
  response: {},
};

export const getFieldOption: IConfig<{ type?: FIELD_OPTION_KEY }, OptionObject[]> = {
  summary: '获取选项',
  method: 'get',
  url: '/api/v1/common/options',
};

export const getTagOptions: IConfig<{ type: string; size?: number }, IPage<Tag>> = {
  summary: '获取选项',
  method: 'get',
  url: '/api/v1/common/tags',
  mock: false,
};

export const getDatasets: IConfig<{ datasetCode?: string }, DatasetVO> = {
  summary: '获取列表的配置(筛选项、隐藏字段等)',
  method: 'get',
  url: '/api/v1/common/datasets/{datasetCode}',
  mock: false,
};

export const getDatasetsColumns: IConfig<{ datasetCode?: string }, any> = {
  summary: '获取列表的可选字段',
  method: 'get',
  url: '/api/v1/common/datasets/{datasetCode}/columns',
  mock: false,
};

export const getDatasetsFilters: IConfig<{ datasetCode?: string; columns?: string[] }, any> = {
  summary: '获取列表的筛选配置',
  method: 'get',
  url: '/api/v1/common/datasets/{datasetCode}/filters',
  mock: false,
};
export const getDatasetsViewDefault: IConfig<{ datasetCode?: string }, DatasetViewVO> = {
  summary: '获取用户默认的列表视图',
  method: 'get',
  url: '/api/v1/common/datasets/{code}/views/default',
  mock: false,
};
export const setDatasetsViewDefault: IConfig<{ datasetCode?: string } & SaveViewRequest, any> = {
  summary: '保存用户默认的列表视图',
  method: 'post',
  url: '/api/v1/common/datasets/{datasetCode}/views/default',
  mock: false,
};
export const getResourceType: IConfig<
  { resourceId: string; type: 'org' | 'project' },
  { resourceStatus: 'pipeline' | 'kickoff' | 'portfolio'; resourceType: 'fund' | 'investment' }
> = {
  summary: '查询资源类型是基金或者直投，并且返回状态',
  method: 'get',
  url: '/api/v1/common/resource',
  mock: false,
};

export const getSettings: IConfig<{ code: string }, any> = {
  summary: '获取某一个用户设置',
  method: 'get',
  url: '/api/v1/common/settings/{code}',
};

export const getAllSettings: IConfig<void, any> = {
  summary: '获取全部用户设置',
  method: 'get',
  url: '/api/v1/common/settings',
};

export const setSettings: IConfig<{ code: SETTING_KEY; content: string }, any> = {
  summary: '保存用户设置',
  method: 'post',
  url: '/api/v1/common/settings',
  mock: false,
};
