import { ProjectEnvEnum } from '@shared/apps/types';

type SharedEnv = {
  platform: string;
};

const env: SharedEnv = {
  platform: null,
};

/**
 * 初始化shared内的环境变量
 * @param platform
 */
export function initSharedEnv(envs: SharedEnv) {
  Object.assign(env, {
    ...envs,
  });
}

/**
 * 是否iOS（包括原生、H5）
 * @returns
 */
export function isIos() {
  return env.platform === 'ios' || isH5Ios();
}

/**
 * 是否Android（包括原生、H5）
 * @returns
 */
export function isAndroid() {
  return env.platform === 'android' || isH5Android();
}

/**
 * 是否H5 iOS编译包（不含原生）
 * @return
 */
function isH5Ios() {
  // H5或web下存在MA_PRODUCT_ENV
  if (process.env.MA_PLATFORM === 'h5') {
    const ua = navigator?.userAgent;
    return !!(
      ua.match(/(iPhone\sOS)\s([\d_]+)/) ||
      ua.match(/(iPad).*OS\s([\d_]+)/) ||
      ua.match(/(iPod)(.*OS\s([\d_]+))?/)
    );
  }

  return false;
}

/**
 * 是否H5 Android编译包（不含原生）
 * @return
 */
function isH5Android() {
  if (process.env.MA_PLATFORM === 'h5') {
    return !!navigator?.userAgent.match(/(Android);?[\s/]+([\d.]+)?/);
  }
  return false;
}

/**
 * 是否H5下调用（非RN）
 * @returns
 */
export function isH5() {
  return process.env.MA_PLATFORM === 'h5';
}

/**
 * 是否为Web编译包，否则为RN（原生或H5）
 * @returns
 */
export function isWeb() {
  // 在pad等内使用web版本，可能会造成误判，所以通过构建变量来判断
  // return !isIos() && !isAndroid();

  // RN的构建不支持注入process.env自定义变量，这里的MA_PLATFORM只在web和H5的webpack层面注入（值分别为web/h5）
  return process.env.MA_PLATFORM === 'web';
}

export function getEnvCount(env: ProjectEnvEnum): number {
  const allEnvCount = {
    [ProjectEnvEnum.Dev]: 7,
    [ProjectEnvEnum.Test]: 4,
    [ProjectEnvEnum.Pre]: 2,
  };

  return allEnvCount[env] ?? 7;
}
