import * as common from './common';
import * as investment from './investment';
import * as versions from './versions';
import * as investorManagement from './investor-management';
import * as workflow from './workflow'
import * as knowledge from './knowledge'
import * as fof from './fof'
import * as query from './query'
import * as payment from './payment'
import * as reports from './reports'
import * as contribution from './contribution'
import * as sheet from './sheet'
import * as portfolioMonitor from './portfolio-monitor'
import * as timPortal from './tim-portal'
import * as legalDatabase from './legal-database'
import * as portfolioReview from './portfolio-review'



const ApiConfig = {
  common,
  investment,
  versions,
  investorManagement,
  workflow,
  knowledge,
  fof,
  query,
  payment,
  reports,
  contribution,
  sheet,
  portfolioMonitor,
  timPortal,
  legalDatabase,
  portfolioReview,
} as const;

type ValueOfUnit<T extends object> = T[keyof T];
type ValueOf<T extends object> = ValueOfUnit<{
  [Key in keyof T]: ValueOfUnit<{
    [SubKey in keyof T[Key]]: T[Key][SubKey];
  }>;
}>;

export default ApiConfig;

export type ApiConfigValueType = ValueOf<typeof ApiConfig>;

export type ApiServiceType<T extends ApiConfigValueType> = (params: T['request']) => Promise<T['response']>;
