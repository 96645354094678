/**
 * 全局项目配置
 */
import { GlobalConfig, ProjectEnvEnum, ProjectEnvRecord, ProjectNameEnum } from '../types';

const PROJECT_ENV_MAPPING: ProjectEnvRecord = {
  [ProjectEnvEnum.Dev]: {
    Web: location.origin, // 'http://portfolio-dev.woa.com',
    EncryptKey: window.atob('YTUzYjc2MzY2N2Q5YTg1NDBhODg5ZTAwZjcyYjkzMWI='),
  },
  [ProjectEnvEnum.Test]: {
    Web: location.origin, // 'http://portfolio-test.woa.com',
    EncryptKey: window.atob('YTUzYjc2MzY2N2Q5YTg1NDBhODg5ZTAwZjcyYjkzMWI='),
  },
  [ProjectEnvEnum.Pre]: {
    Web: location.origin, // 'http://portfolio-pre.woa.com',
    EncryptKey: window.atob('MTJmMWMwZDEyNzVhMDg3MjJhOTNiOGNkYjk3YzY2OTk='),
  },
  [ProjectEnvEnum.Prod]: {
    Web: location.origin, // 'http://portfolio.woa.com',
    EncryptKey: window.atob('MTJmMWMwZDEyNzVhMDg3MjJhOTNiOGNkYjk3YzY2OTk='),
  },
};

const GLOBAL_CONFIG: GlobalConfig = {
  projectName: ProjectNameEnum.TIM_PORTAL,
  projectEnv: PROJECT_ENV_MAPPING,
  aegisIdPc: 'OekK08BZEGGrMVr5Xq',
  aegisIdApp: '',
};

export default GLOBAL_CONFIG;
