import type { ApiConfigValueType } from '@shared/api/config';
import type { NonUndefined } from '@shared/typings/utils';
import logger from '@shared/utils/logger';
import { loading, MessagePlugin } from 'tdesign-react';
import { parseUrl, axiosInstance } from '@shared/libs/http';
import { cacheAdapterEnhancer } from 'axios-extensions';
import axios, { AxiosRequestConfig } from 'axios';
import { Api } from '@shared/api';
import { isDevelopment, isOutputLog } from '@src/helper/env';
import _ from 'lodash';
import { getLocal, STORAGE_KEY } from '@src/libs/localStorage';
import { store } from '@src/modules/tim-portal/store';
interface ApiOptions {
  /**
   * 展示Loading
   * @default false
   */
  needLoading?: boolean;
  /**
   * @default true
   */
  showErrorToast?: boolean;
}

const initBaseUrl = () => {
  if (process.env.MOCK_ENABLE === 'true') {
    return `http://127.0.0.1:${process.env.MOCK_PORT}`;
  }

  return window.location.origin;
};

const baseUrl = initBaseUrl();

Api._hasLog = isDevelopment() && isOutputLog();

/**
 * 发送请求公共方法
 */
Api.init({
  request<T extends ApiConfigValueType>(
    config: T,
    params: T['request'] = {},
    options: ApiOptions & AxiosRequestConfig = {},
  ): Promise<NonUndefined<T['response']>> {
    const requestConfig = { ...config } as any;
    const _mergeOption = {
      needLoading: false,
      showErrorToast: false,
      ...options,
    };
    const axiosConfig = _.omit(options, ['needLoading', 'showErrorToast']);

    Object.keys(axiosConfig).forEach((key) => {
      requestConfig[key] = axiosConfig[key];
    });

    // 0. 添加一些默认参数
    requestConfig.baseURL = baseUrl;
    requestConfig.timeout = 60 * 1000;
    requestConfig.adapter = (_config) =>
      cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
        cacheFlag: 'useCache',
      })(_config);

    // 当token存在时，请求header中携带token
    if (getLocal(STORAGE_KEY.TOKEN)) {
      requestConfig.headers = Object.assign(requestConfig.headers || {}, {
        Authorization: `Bearer ${getLocal(STORAGE_KEY.TOKEN)}` || '',
      });
    }

    // 1. 展示Loading
    if (_mergeOption?.needLoading) {
      this._loadInstance = loading(true);
    }

    // 2. 替换url中的变量
    const { url, requestParams } = parseUrl(requestConfig.url, params);
    requestConfig.url = url;
    if (requestParams.abortController?.signal) {
      requestConfig.signal = requestParams.abortController.signal;
      delete requestParams.abortController;
    }

    // 3. 打印请求log
    this._requestId = this._requestId + 1;
    const rid = this._requestId;
    this._hasLog && logger.logRequest({ config, params, rid });

    // 4. 根据method组装参数
    if (['post', 'put'].includes(requestConfig.method?.toLowerCase())) {
      requestConfig.data = requestParams;
    } else {
      requestConfig.params = requestParams;
    }

    // 5. 如果设置了mock，使用mock接口请求
    if (process.env?.MOCK_ENABLE === 'true' && process.env.MOCK_PORT) {
      requestConfig.baseURL = `http://127.0.0.1:${process.env.MOCK_PORT}/api`;
    }

    return axiosInstance.request(requestConfig).then(
      async (response) => {
        const { headers } = response;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        let responseData = { ...response.data, __environment: headers?.environment };
        let requestResult = { type: 'error', data: responseData };
        if (config?.mock && config?.response) {
          responseData = config?.response;
        }
        if (typeof responseData === 'object' && responseData !== null && responseData.code !== undefined) {
          const { code, data } = responseData;

          if (code === 100000) {
            requestResult = { type: 'success', data };
          } else {
            requestResult = { type: 'error', data: responseData || '系统错误' };
            // userme 接口每次都会请求
            if (code === 100900 && requestConfig.url !== '/api/v1/portal/user/me') {
              const { dispatch } = store;
              // token 过期清空token并跳转登录页
              dispatch.global.clearLoginState().then(() => {
                history.replaceState(null, '', '/login');
              });
            }
          }

          if (process.env?.MOCK_ENABLE === 'true') {
            requestResult = { type: 'success', data: responseData };
          }
        }

        // 1. 按照结果打印log
        if (this._hasLog) {
          if (config.mock) {
            logger.logMockResponse({ config, params, rid, data: requestResult.data });
          } else {
            requestResult.type === 'error'
              ? logger.logErrorResponse({ config, params, rid, data: requestResult.data })
              : logger.logResponse({ config, params, rid, data: requestResult.data });
          }
        }

        // 2. 隐藏loading
        _mergeOption?.needLoading && this._loadInstance?.hide();

        // 3. 展示toast
        requestResult.type === 'error' && _mergeOption?.showErrorToast && MessagePlugin.error(requestResult.data);

        // 4. 返回结果
        return requestResult.type === 'error'
          ? Promise.reject(requestResult.data)
          : Promise.resolve(requestResult.data);
      },
      (err) => {
        _mergeOption?.needLoading && this._loadInstance.hide();

        const newErr = new Error(`请求异常 ${err.message ? ` (${err.message})` : ''}`);
        _mergeOption?.showErrorToast && MessagePlugin.error(newErr.message);
        logger.logErrorResponse({ config, params, rid, data: newErr });
        return Promise.reject(newErr);
      },
    );
  },
});
