export enum AppEventName {
  // APP 用到的 EventName
  pipelineRefresh, // 投前列表刷新事件
  kickoffRefresh, // 投中列表刷新事件
  portfolioRefresh, // 投后列表刷新事件
  gpListRefresh, // gp列表刷新事件
  teamContactUpdate, // 公司高管信息更新数据
  fofTeamContactUpdate, // fof人员信息更新数据
  meetingListRefresh, // 例会列表刷新事件
  meetingProjectListRefresh, // 例会上会项目刷新事件
  kickoffCompanyInfo, // 投中公司信息
  kickoffMemberUpdate, // 投中公司信息
  KickoffDetailRefresh, // 投中详情页面
  MyVoteTodoList, // 待我投票刷新
  fofPipelineRefresh, // fof 投前列表
  fofKickoffRefresh, // fof 投中列表
  fofPortfolioRefresh, // fof 投后列表
  FoFKickoffDetailRefresh, // fof 投中详情页刷新
  paymentRefresh, // 付款列表刷新
  myVoteListRefresh, // 我的投票更新行数据
  dealApprovalListRefresh, // 执行审批行数据更新
  dataVerifyListRefresh, // 交割数据审核更新
  archiveListRefresh, // 入库审核更新
  signoffListRefresh, // 签署审核列表行数据
  signoffApprovalListRefresh, // 签署审核行数据
  signoffApprovalAppCountRefresh, // 签署审核数量更新
  signoffApprovalListRefreshBatch, // 签署审核行数据批量更新
  signoffFormalApprovalListRefresh, // 发起正式签署行数据
  signoffESignoffApprovalListRefresh, // 电子签名行数据
  portfolioReviewSwitchCompany, // 切换Portfolio Review公司
  portfolioReviewViewSwitchCompany, // 视图模式下切换Portfolio Review公司
  portfolioReviewViewGetCompany, // 视图模式下切换Portfolio Review加载公司
  transactionsRefresh,
  tppPaymentListRefresh, // tpp付款列表刷新
  tppPaymentApprovalListRefresh, // tpp 付款审批列表刷新
  tppPaymentApprovalBatchListRefresh, // tpp 批量付款审批列表刷新
  tppPaymentFormalApprovalListRefresh, // tpp 发起正式付款列表刷新
  gatewaySessionTimeout, // 智能网关会话超时，需要302重定向
  clearCookie, // 清除app cookie
}

// APP 和 PC 都用到的eventName
export enum SharedEventName {
  RefreshWorkflow = 'RefreshWorkflow', // 刷新审批流数据
  refreshFofInvestmentOverview = 'refreshFofInvestmentOverview', // 刷新fof腾讯投资概览
  WorkflowStageChange = 'WorkflowStageChange', // 审批流阶段变更
}

export type Fn = (...args: any) => void;

export class EventBus<T, FnType extends (args?: any) => any = Fn> {
  private listeners = new Map<T, FnType[]>();

  getEvents(eventName: T): FnType[] {
    return this.listeners.get(eventName) ?? [];
  }

  listen(eventName: T, fn: FnType) {
    const events = this.getEvents(eventName);
    this.listeners.set(eventName, [...events, fn]);
    return () => {
      this.listeners.set(
        eventName,
        this.getEvents(eventName).filter((item) => item !== fn),
      );
    };
  }

  trigger<Arg extends any[] = any>(eventName: T, ...args: Arg) {
    this.getEvents(eventName).forEach((fn) => fn?.(...(args ?? [])));
  }

  clear(eventName?: T, fn?: FnType) {
    if (eventName) {
      if (fn) {
        const fns = this.getEvents(eventName);
        this.listeners.set(
          eventName,
          fns.filter((f) => f !== fn),
        );
      } else {
        this.listeners.set(eventName, []);
      }
    } else {
      this.listeners = new Map();
    }
  }
}

/**
 * 全局单例eventbus，方便在一些非RN（纯函数等）内注册调用
 */
export const GlobalEventBus = new EventBus();
