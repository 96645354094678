/**
 * 版本升级相关
 */
import type { IConfig } from './types';

const getVersions: IConfig<
  { type: string; platform?: string; version?: string | number; bundleVersion?: string | number },
  any
> = {
  summary: '获取版本信息',
  method: 'get',
  url: '/api/v1/common/versions',
  mock: false,
};

export { getVersions };
