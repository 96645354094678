import type { IConfig } from './types';

export const getSheetList: IConfig<any, any> = {
  summary: '获取Sheet名称列表',
  method: 'get',
  url: '/api/v1/pm/records',
  mock: false,
};

export const getSheetDetail: IConfig<{ id: string }, any> = {
  summary: '获取Sheet详情',
  method: 'get',
  url: '/api/v1/pm/records/{id}/tables/business',
  mock: false,
};

export const updateSheetDetail: IConfig<{ id: string; data: any }, any> = {
  summary: '保存Sheet',
  method: 'post',
  url: '/api/v1/pm/sheets/{id}',
  mock: false,
};
