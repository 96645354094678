/*
 * @Author: yatesmiao
 * @Date: 2022-10-14 15:17:16
 * @LastEditors: yatesmiao
 * @LastEditTime: 2022-10-24 18:35:39
 * @Description: 知识库接口定义
 */

import type { IOptions } from '@shared/typings/option';
import type { ArticleRequest, ArticleVO, PersonInfoVO } from '../interface';
import type { IConfig } from './types';

export const createKnowledge: IConfig<ArticleRequest, ArticleVO> = {
  summary: '创建知识库报告',
  method: 'post',
  url: '/api/v1/km/articles',
  mock: false,
  request: {},
  response: {},
};

export const getKnowledgeDetail: IConfig<{ articleId: string }, ArticleVO> = {
  summary: '查看知识库报告详情',
  method: 'get',
  url: '/api/v1/km/articles/{articleId}',
  mock: false,
  response: {},
};

export const deleteKnowledge: IConfig<{ articleId: string }, any> = {
  summary: '删除知识库报告',
  method: 'delete',
  url: '/api/v1/km/articles/{articleId}',
  mock: false,
  response: {},
};

export const updateKnowledge: IConfig<{ articleId: string }, any> = {
  summary: '编辑知识库报告',
  method: 'put',
  url: '/api/v1/km/articles/{articleId}',
  mock: false,
  response: {},
};

export const getKnowledgeList: IConfig<any, any> = {
  summary: '获取知识库列表',
  method: 'get',
  url: '/api/v1/km/articles',
  mock: false,
  request: {},
  response: {},
};

export const followArtical: IConfig<{ articleId: string }, any> = {
  summary: '收藏知识库文章',
  method: 'post',
  url: '/api/v1/km/articles/{articleId}/follows',
  mock: false,
  response: {},
};

export const cancelFollowArtical: IConfig<{ articleId: string }, any> = {
  summary: '取消收藏知识库文章',
  method: 'delete',
  url: '/api/v1/km/articles/{articleId}/follows',
  mock: false,
  response: {},
};

export const getKnowledgeFilterDataSource: IConfig<
  {
    type: string[];
  },
  {
    KM_ARTICLE_SOURCE: IOptions;
    KM_ARTICLE_MODEL_INTERNAL: IOptions;
    KM_ARTICLE_MODEL_EXTERNAL: IOptions;
    PROFESSION_ALL: IOptions;
    KM_SEARCH_OPTIONS?: IOptions;
  }
> = {
  summary: '拉取知识库筛选数据',
  url: '/api/v1/common/options',
  method: 'get',
};

export const getRecommendationArticle: IConfig<any, any> = {
  summary: '查询推荐报告',
  method: 'get',
  url: '/api/v1/km/articles/recommendation-article',
  mock: false,
  response: {},
};

export const saveRecommendationArticle: IConfig<any, any> = {
  summary: '保存推荐报告',
  method: 'post',
  url: '/api/v1/km/articles/recommendation-article',
  mock: false,
  response: {},
};

export const getCreateArticleList: IConfig<any, any> = {
  summary: '获取我发布的知识库报告列表',
  method: 'get',
  url: '/api/v1/km/articles/my-create',
  mock: false,
  response: {},
};

export const getFollowArticleList: IConfig<any, any> = {
  summary: '获取我收藏的知识库报告列表',
  method: 'get',
  url: '/api/v1/km/articles/my-follow',
  mock: false,
  response: {},
};

export const getPersonInfo: IConfig<{}, PersonInfoVO> = {
  summary: '获取个人信息中心',
  method: 'get',
  url: '/api/v1/km/articles/person-info',
  mock: false,
  response: {},
};
