/* eslint-disable @typescript-eslint/naming-convention */
import { each, getConstructorOf, isInheritedOf, isFunction, isInstanceOf, isObject } from 'ts-fns';
import { Model, eject } from 'tyshemo';
import { useMemo, useEffect } from 'react';
import { useForceUpdate } from '@shared/hooks/force-update';
import { Store } from './store/store';
import { Stream } from './stream';
import { Service } from './service';
import { DataService } from './services/data-service';
import { PrimitiveBase, ofChainStatic } from './utils/utils';

/**
 * class SomeController extends Controller {
 *   static vote = VoteModel
 *
 *   static vote$(stream) {
 *     stream.subscribe(() => this.vote.count ++ )
 *   }
 * }
 */
export class Controller extends PrimitiveBase {
  @eject() observers: any[];
  @eject() emitters: any[];

  __init() {
    this.observers = [];
    this.emitters = [];
    this.dispatch = this.dispatch.bind(this);

    const Constructor = getConstructorOf(this);
    const streams = [];
    const staticProperties = ofChainStatic(Constructor, Controller);
    each(
      staticProperties,
      (_, key) => {
        const Item = Constructor[key];
        if (Item && isInheritedOf(Item, DataService)) {
          const ins = Item.instance();
          this[key] = ins;
          // notice that, any data source change will trigger the rerender
          // so you should must pass collect to determine when to rerender, look into example of `reactive`
          this.observe((dispatch) => {
            ins.subscribe(dispatch);
            return () => {
              ins.unsubscribe(dispatch);
            };
          });
        } else if (Item && isInheritedOf(Item, Service)) {
          this[key] = Item.instance();
        } else if (Item && isInheritedOf(Item, Model)) {
          this[key] = new Item();
          this.observe(this[key]);
        } else if (Item && (Item === Store || isInheritedOf(Item, Store))) {
          this[key] = new Item();
          this.observe(this[key]);
        } else if (isFunction(Item) && key[key.length - 1] === '$') {
          const stream$ = new Stream();
          this[key] = stream$;
          streams.push([Item, stream$]);
        }
      },
      true,
    );
    // register all streams at last, so that you can call this.stream$ directly in each function.
    streams.forEach(([fn, stream$]) => fn.call(this, stream$));

    // start
    this.observers.forEach(({ start }) => start());
  }

  subscribe(fn) {
    this.emitters.push(fn);
  }

  unsubscribe(fn) {
    this.emitters = this.emitters.filter((item) => item !== fn);
  }

  dispatch() {
    this.emitters.forEach((fn) => {
      fn();
    });
  }

  destructor() {
    super.destructor();
    // when controller is not active, clear all
    this.observers?.forEach(({ stop }) => stop());
    this.observers = [];
    this.emitters = [];
  }

  observe(observer) {
    if (isInstanceOf(observer, Store)) {
      const subscription = {
        start: () => observer.subscribe(this.dispatch),
        stop: () => observer.unsubscribe(this.dispatch),
        observer,
      };
      this.observers.push(subscription);
    } else if (isInstanceOf(observer, Model)) {
      const subscription = {
        start: () => {
          observer.watch('*', this.dispatch, true);
          observer.watch('!', this.dispatch, true);
          observer.on('recover', this.dispatch);
        },
        stop: () => {
          observer.unwatch('*', this.dispatch);
          observer.unwatch('!', this.dispatch);
          observer.off('recover', this.dispatch);
        },
        observer,
      };
      this.observers.push(subscription);
    } else if (isFunction(observer)) {
      let unsubscribe = null;
      const subscription = {
        start: () => {
          unsubscribe = observer(this.dispatch);
        },
        stop: () => {
          if (isFunction(unsubscribe)) {
            unsubscribe(this.dispatch);
            unsubscribe = null;
          }
        },
        observer,
      };
      this.observers.push(subscription);
    } else if (isObject(observer)) {
      const { subscribe, unsubscribe } = observer;
      const subscription = {
        start: () => subscribe(this.dispatch),
        stop: () => (isFunction(unsubscribe) ? unsubscribe(this.dispatch) : null),
        observer,
      };
      this.observers.push(subscription);
    }
  }

  disobserve(observer) {
    const index = this.observers.findIndex((item) => item.observer === observer);
    if (index === -1) {
      return;
    }

    const item = this.observers[index];
    item.stop();
    this.observers.splice(index, 1);
  }
}

export function useController<T extends Controller>(Ctrl: new () => T): T {
  // @ts-ignore
  const controller: Controller = useMemo(() => new Ctrl(), [Ctrl]);
  const froceUpdate = useForceUpdate();

  useEffect(() => {
    controller.subscribe(froceUpdate);
    return () => {
      controller.unsubscribe(froceUpdate);
      controller.destructor();
    };
  }, []);

  // @ts-ignore
  return controller;
}
