import { useState } from 'react';
import classNames from 'classnames';
import { $i18next } from '@shared/i18n';
import * as styles from './switch-language.less';

export const SwitchLanguage = (props) => {
  const [language, setLanguage] = useState($i18next.getLanguage());

  const onLangClick = (lang) => {
    setLanguage(lang);
    $i18next.setLanguage(lang);
  };

  const { className } = props;
  return (
    <div className={classNames(styles.container, className)} {...props}>
      <span
        className={classNames(styles.button, { [styles.activeLanguage]: language.indexOf('zh') > -1 })}
        onClick={() => onLangClick('zh')}
      >
        {/* @i18n-ignore */}
        {$i18next.t('tim-portal:aed1dfbc:中')}
      </span>
      <span>/</span>
      <span
        className={classNames(styles.button, { [styles.activeLanguage]: language.indexOf('en') > -1 })}
        onClick={() => onLangClick('en')}
      >
        {/* @i18n-ignore */}
        EN
      </span>
    </div>
  );
};
