import apiConfig from '@shared/api/config';
import type { ApiConfigValueType } from '@shared/api/config';
import type { NonUndefined } from '@shared/typings/utils';
import { AxiosRequestConfig } from 'axios';
interface ApiOptions {
  /**
   * 展示Loading
   * @default false
   */
  needLoading?: boolean;
  /**
   * @default true
   */
  showErrorToast?: boolean;
}
class ApiService {
  static config = apiConfig;
  public _hasLog: boolean;
  public _requestId: number;

  get config() {
    return ApiService.config;
  }
  constructor() {
    this._hasLog = false;
    this._requestId = 0;
  }
  init(options) {
    this.request = options.request;
  }
  // 在web和app分别实现
  request<T extends ApiConfigValueType>(
    config: T,
    params: T['request'] = {},
    options: ApiOptions & AxiosRequestConfig = {},
  ): Promise<NonUndefined<T['response']>> {
    return Promise.resolve(undefined);
  }
}

export { default as config } from './config';
export const Api = new ApiService();
export const ApiConfig = ApiService.config;
