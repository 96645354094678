// 被投门户
import type { IConfig } from './types';

export const login: IConfig<any, any> = {
  summary: '登录',
  method: 'post',
  url: '/api/v1/login',
  mock: false,
};

export const logout: IConfig<any, any> = {
  summary: '登出',
  method: 'delete',
  url: '/api/v1/logout',
  mock: false,
};

export const getUserInfo: IConfig<any, any> = {
  summary: '个人信息接口',
  method: 'get',
  url: '/api/v1/user/me',
  mock: false,
};

export const changePassword: IConfig<any, any> = {
  summary: '修改密码',
  method: 'put',
  url: '/api/v1/user/password/reset',
  mock: false,
};

export const sendVerifyCode: IConfig<{ account: string; randStr: string; ticket: string }, any> = {
  summary: '发送邮箱验证码',
  method: 'post',
  url: '/api/v1/forget/send',
  mock: false,
};

export const VerifyVerificationCode: IConfig<{ account: string; captcha: string }, any> = {
  summary: '验证邮箱验证码',
  method: 'post',
  url: '/api/v1/forget/verify',
  mock: false,
};

export const resetPassword: IConfig<{ account: string; password: string; token: string }, any> = {
  summary: '重置密码',
  method: 'put',
  url: '/api/v1/forget/reset',
  mock: false,
};

export const getCompanyList: IConfig<any, any> = {
  summary: '获取关联公司列表',
  method: 'get',
  url: '/api/v1/records',
  mock: false,
};

export const getHomeData: IConfig<any, any> = {
  summary: '获取最新填写数据',
  method: 'get',
  url: '/api/v1/records/latest',
  mock: false,
};

export const getSheetDetail: IConfig<{ recordId: string }, any> = {
  summary: '获取最新填写数据',
  method: 'get',
  url: '/api/v1/records/{recordId}/tables/business',
  mock: false,
};
export const getVersionDetail: IConfig<{ recordId: string; versionId: string }, any> = {
  summary: '获取历史版本详情',
  method: 'get',
  url: '/api/v1/records/{recordId}/tables/business/versions/{versionId}',
  mock: false,
};

export const getVersionHistory: IConfig<{ recordId: string; tableId: string }, any> = {
  summary: '获取历史版本列表',
  method: 'get',
  url: '/api/v1/records/{recordId}/tables/business/versions',
  mock: false,
  response: {},
};

export const getAttachments: IConfig<{ recordId: string }, any> = {
  summary: '获取附件列表',
  method: 'get',
  url: '/api/v1/records/{recordId}/tables/business/attachments',
  mock: false,
  response: {},
};

export const deleteAttachment: IConfig<{ recordId: string; attachmentId: string }, any> = {
  summary: '删除附件',
  method: 'delete',
  url: '/api/v1/records/{recordId}/tables/business/attachments/{attachmentId}',
  mock: false,
  response: {},
};

export const uploadAttachment: IConfig<{ recordId: string; file: any }, any> = {
  summary: '上传附件',
  method: 'post',
  url: '/api/v1/records/{recordId}/tables/business/attachments/upload',
  mock: false,
  response: {},
};

export const saveSheetDetail: IConfig<{ recordId: string; version: string; data: any }, any> = {
  summary: '保存表格',
  method: 'post',
  url: '/api/v1/records/{recordId}/tables/business',
  mock: false,
  response: {},
};

export const submitSheetDetail: IConfig<{ recordId: string; version: string; data: any }, any> = {
  summary: '提交表格',
  method: 'post',
  url: '/api/v1/records/{recordId}/tables/business/submit',
  mock: false,
  response: {},
};
