import { Service, eject } from '@core';
import { isShallowEqual } from 'ts-fns';

export class QueueService extends Service {
  @eject() private queue: { action: Function; fn: Function; args: any[] }[];

  init() {
    this.queue = [];
    const run = async () => {
      const item = this.queue.shift();
      const req = requestAnimationFrame(run);
      if (!item) {
        return;
      }
      cancelAnimationFrame(req);
      const { action } = item;
      try {
        await action();
        requestAnimationFrame(run);
      } catch (e) {
        console.error(e, item);
      }
    };
    run();
    // 先注释，可能陷入卡帧，不使用setTimeout魔法数字
    // const setup = async () => {
    //   const startTime = Date.now();
    //   const act = async () => {
    //     await run();
    //     const endTime = Date.now();
    //     if (endTime - startTime >= 32) {
    //       setTimeout(setup, 32);
    //     } else {
    //       await act();
    //     }
    //   };
    //   act();
    // };
    // setup();
  }

  push<T extends any[] = any[], R = any>(fn: (...args: T) => R | Promise<R>, ...args: T): Promise<R> {
    let resolveFn = null;
    let rejectFn = null;
    const deferredPromise = new Promise<R>((res, rej) => {
      resolveFn = res;
      rejectFn = rej;
    });
    // 目的是把fn 包装成promise
    const action = async () => {
      try {
        const result = await fn(...args);
        resolveFn(result);
      } catch (error) {
        rejectFn(error);
      }
    };
    this.queue.push({ fn, args, action });
    return deferredPromise;
  }

  /**
   * 判断是否已经注册过了
   * @param fn
   * @param args
   * @returns
   */
  hasRegistered<T extends any[] = any[], R = any>(fn: (...args: T) => R | Promise<R>, ...args: T): boolean {
    return this.queue.some((item) => item.fn === fn && isShallowEqual(item.args, args));
  }

  static push<T extends any[] = any[], R = any>(fn: (...args: T) => R | Promise<R>, ...args: T): Promise<R> {
    const deamon = this.instance();
    return deamon.push(fn, ...args);
  }
}

// 立即创建一个单例，支持 #push 方法
// 该单例不可销毁，永远存在
QueueService.instance();
