// 董事会季报接口

import type { IConfig } from './types';

export const reportsProjectList: IConfig<any, any> = {
  summary: '董事会季报列表',
  method: 'get',
  url: '/api/v1/reports/projects',
  mock: false,
};

export const reports: IConfig<any, any> = {
  summary: '创建董事会季报',
  method: 'post',
  url: '/api/v1/reports',
  mock: false,
};

export const updataReports: IConfig<any, any> = {
  summary: '修改董事会季报配置',
  method: 'put',
  url: '/api/v1/reports/{reportId}',
  mock: false,
};

export const saveReports: IConfig<any, any> = {
  summary: '保存董事会季报信息',
  method: 'put',
  url: '/api/v1/reports/{reportId}/projects',
  mock: false,
};

export const getTemplate: IConfig<any, any> = {
  summary: '获取季报项目对应的公司简述、Deal简述的模板',
  method: 'get',
  url: '/api/v1/reports/{reportId}/projects/{id}/template',
  mock: false,
};

export const submitReports: IConfig<any, any> = {
  summary: '提交季报项目',
  method: 'put',
  url: '/api/v1/reports/{reportId}/projects/submit',
  mock: false,
};

export const publishReports: IConfig<any, any> = {
  summary: '发布季报项目',
  method: 'put',
  url: '/api/v1/reports/{reportId}/publish',
  mock: false,
};

export const exportReports: IConfig<any, any> = {
  summary: '导出',
  method: 'get',
  url: '/api/v1/reports/export',
  mock: false,
};

export const reportsList: IConfig<any, any> = {
  summary: '获取季报期数列表',
  method: 'get',
  url: '/api/v1/reports',
  mock: false,
};

export const reportsTodo: IConfig<any, any> = {
  summary: '待办',
  method: 'get',
  url: '/api/v1/reports/todo',
  mock: false,
};

export const reportsOptions: IConfig<
  {
    type: string[];
  },
  any
> = {
  summary: '拉取季报筛选数据',
  url: '/api/v1/common/options',
  method: 'get',
};
