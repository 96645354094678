import { Service, inject, QueueService } from '@core';

export type MessageType = 'success' | 'error' | 'warning' | 'info' | 'alert' | 'confirm';

export class MessageQueueService extends Service {
  @inject(QueueService)
  queue: QueueService;

  subscribers = [];

  notify = async (type: MessageType, message: string): Promise<void> => {
    const deferers = this.subscribers.map((fn) => Promise.resolve().then(() => fn(type, message)));

    await Promise.all(deferers);
  };

  subscribe(fn: (type: MessageType, message: string, ...args: any[]) => void) {
    this.subscribers.push(fn);
  }

  push(type: MessageType, message: string, ...args: any[]): Promise<void> {
    if (this.queue.hasRegistered(this.notify, type, message, ...args)) {
      return Promise.resolve();
    }
    return this.queue.push(this.notify, type, message, ...args);
  }

  static push(type: MessageType, message: string, ...args: any[]): Promise<void> {
    const messageQueue = this.instance();
    return messageQueue.push(type, message, ...args);
  }
}

// 立即创建一个单例，支持 #push 方法
// 该单例不可销毁，永远存在
MessageQueueService.instance();
