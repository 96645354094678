/**
 *
 */

import { GlobalConfig } from './types';

let globalConfig: GlobalConfig = null;

/**
 * 初始化项目配置
 * @param config
 */
export function initGlobalConfig(config: GlobalConfig) {
  globalConfig = config;
}

/**
 * 获取全局配置
 * @returns
 */
export function getGlobalConfig() {
  return {
    ...globalConfig,
  };
}
