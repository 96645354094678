import type { IConfig } from './types';

export const getVersionHistory: IConfig<{ recordId: string; tableId: string }, any> = {
  summary: '获取历史版本列表',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables/{tableId}/versions',
  mock: false,
  response: {},
};

export const getVersionInfo: IConfig<{ recordId: string; tableId: string }, any> = {
  summary: '获取表格当前版本信息',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables/{tableId}/current-versions',
  mock: false,
  response: {},
};
export const saveBusinessTableDetail: IConfig<{ recordId: string; version: string; data: any }, any> = {
  summary: '保存业务数据表格',
  method: 'post',
  url: '/api/v1/pm/records/{recordId}/tables/business',
  mock: false,
  response: {},
};

export const getHistoryVersionDetail: IConfig<{ recordId: string; versionId: string }, any> = {
  summary: '获取历史版本表格详情',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables/business/versions/{versionId}',
  mock: false,
  response: {},
};

export const getRecoredsTableList: IConfig<{ recordId: string }, any> = {
  summary: '获取记录（公司）表格列表',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables',
  mock: false,
  response: {},
};

export const submitShareVersion: IConfig<
  { recordId: string; data: any; remark?: string; notify: number; status: string; version: string },
  any
> = {
  summary: '提交共享版本',
  method: 'post',
  url: '/api/v1/pm/records/{recordId}/tables/business/publish',
  mock: false,
  response: {},
};

export const submitAuditResult: IConfig<
  { recordId: string; data: any; remark?: string; performance: number; status: string; version: string },
  any
> = {
  summary: '提交审核结果',
  method: 'post',
  url: '/api/v1/pm/records/{recordId}/tables/business/audit',
  mock: false,
  response: {},
};

export const rollbackSheet: IConfig<{ recordId: string }, any> = {
  summary: '收回编辑表格',
  method: 'patch',
  url: '/api/v1/pm/records/{recordId}/tables/business/rollback',
  mock: false,
  response: {},
};

export const getAttachments: IConfig<any, any> = {
  summary: '获取附件列表',
  method: 'get',
  url: '/api/v1/pm/sheets',
  mock: false,
  response: {},
};

export const getRemark: IConfig<{ recordId: string; tableId: string }, any> = {
  summary: '获取备注列表',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables/{tableId}/comments',
  mock: false,
  response: {},
};

export const getPortfolioReviewDisplayAreas: IConfig<{ recordId: string }, any> = {
  summary: '获取展示区域',
  method: 'get',
  url: '/api/v1/pm/records/{recordId}/tables/business/display-areas',
  mock: false,
  response: {},
};

export const setPortfolioReviewDisplayAreas: IConfig<
  {
    recordId: string;
    versionId: string;
    sheetIndex: string;
    startColumn: string;
    endColumn: string;
    startRow: string;
    endRow: string;
  },
  any
> = {
  summary: '保存展示区域',
  method: 'post',
  url: '/api/v1/pm/records/{recordId}/tables/business/display-areas',
  mock: false,
  response: {},
};
