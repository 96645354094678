/**
 * Footer
 */

import { Footer as Foot } from '@src/modules/tim-portal/components/footer/footer';
import * as styles from './footer.less';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <Foot />
    </div>
  );
}
