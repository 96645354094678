import qs from 'qs';
import { STORAGE_KEY, getLocal, setLocal } from '@src/libs/localStorage';
import { isDebug } from './env';

// url有ghostlogin参数，提前写入缓存
initGhostUser();

function getGhostUserFromUrl() {
  const parsed = qs.parse(window?.location?.search, { ignoreQueryPrefix: true });
  return parsed.ghostlogin || '';
}

function initGhostUser() {
  if (!isDebug()) {
    return;
  }

  const user = getGhostUserFromUrl();
  if (user) {
    setLocal(STORAGE_KEY.GHOSTLOGIN, user);
  }
}

/**
 * 获取ghostlogin用户, dev/test only
 * @return
 */
export function getGhostUser() {
  if (isDebug()) {
    const ghostUser = getLocal(STORAGE_KEY.GHOSTLOGIN);
    return ghostUser || '';
  }

  return '';
}
