/*
 * @Author: yatesmiao
 * @Date: 2022-08-23 16:51:40
 * @LastEditors: yatesmiao
 * @LastEditTime: 2023-06-19 10:39:55
 * @Description: 主体管理接口定义
 */

import type { IConfig } from './types';

export const createCorporation: IConfig<any, any> = {
  summary: '新建主体',
  method: 'post',
  url: '/api/v1/corporations',
  mock: false,
  request: {},
  response: {},
};

export const getCorporation: IConfig<any, any> = {
  summary: '主体详情',
  method: 'get',
  url: '/api/v1/corporations/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const deleteCorporation: IConfig<any, any> = {
  summary: '删除主体',
  method: 'delete',
  url: '/api/v1/corporations/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporation: IConfig<any, any> = {
  summary: '更新主体',
  method: 'put',
  url: '/api/v1/corporations/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationDelegations: IConfig<any, any> = {
  summary: '编辑主体人员信息',
  method: 'put',
  url: '/api/v1/corporations/delegation/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationBrokerage: IConfig<any, any> = {
  summary: '编辑主体券商信息',
  method: 'put',
  url: '/api/v1/corporations/brokerage/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationShareholder: IConfig<any, any> = {
  summary: '编辑主体股东信息',
  method: 'put',
  url: '/api/v1/corporations/shareholder/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationDetails: IConfig<any, any> = {
  summary: '编辑基本信息（其他信息）',
  method: 'put',
  url: '/api/v1/corporations/details/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationBank: IConfig<any, any> = {
  summary: '编辑主体银行信息',
  method: 'put',
  url: '/api/v1/corporations/bank/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationDelegationsOther: IConfig<any, any> = {
  summary: '编辑非腾讯主体人员信息',
  method: 'put',
  url: '/api/v1/corporations/delegation/other/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationListing: IConfig<any, any> = {
  summary: '编辑主体上市信息',
  method: 'put',
  url: '/api/v1/corporations/listing/{corporationId}',
  mock: false,
  request: {},
  response: {},
};

export const updateCorporationAttachment: IConfig<any, any> = {
  summary: '编辑主体相关文档',
  method: 'put',
  url: '/api/v1/corporations/document/{corporationId}',
  mock: false,
  request: {},
  response: {},
};
export const searchInvestCompany: IConfig<{ keyword?: string }, any> = {
  summary: '搜索关联直投公司',
  method: 'get',
  url: '/api/v1/corporations/company/search',
  mock: false,
  request: {},
  response: {},
};
export const searchFundCompany: IConfig<{ keyword?: string }, any> = {
  summary: '搜索关联基金',
  method: 'get',
  url: '/api/v1/fof/funds/search',
  mock: false,
  request: {},
  response: {},
};

export const searchInvestorCorporation: IConfig<{ keyword?: string }, any> = {
  summary: '搜索腾讯投资方主体',
  method: 'get',
  url: '/api/v1/corporations/search',
  mock: false,
  request: {},
  response: {},
};

export const corporationImport: IConfig<{ file?: File }, any> = {
  summary: '其他主体导入',
  method: 'post',
  url: '/api/v1/corporations/import',
  mock: false,
  request: {},
  response: {},
};
