import { getGlobalConfig } from '@shared/apps';
import { ProjectEnvEnum } from '@shared/apps/types';

/**
 * 获取项目的环境对应的配置
 * @return
 */
function getProjectEnvConfig() {
  const env = getProjectEnv();
  const globalConfig = getGlobalConfig();
  const config = globalConfig.projectEnv?.[env];

  if (globalConfig.projectEnv && !config) {
    return globalConfig.projectEnv[ProjectEnvEnum.Prod];
  }

  return {
    ...config,
  };
}

// MA_PRODUCT_ENV=dev/test/pre/prod，会由蓝盾传入
function getProjectEnv(): ProjectEnvEnum {
  return process.env.MA_PRODUCT_ENV as ProjectEnvEnum;
}

function isProduction() {
  return process.env.NODE_ENV === 'production';
}

function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

function isOutputLog() {
  return process.env.OUTPUT_REQUEST_LOG === 'true';
}

// 是否调试模式，包括本地开发、开发环境、测试环境
function isDebug() {
  return isDevelopment() || getProjectEnv() === ProjectEnvEnum.Dev || getProjectEnv() === ProjectEnvEnum.Test;
}

// 是否支持子环境，开发/测试/预发布都支持子环境切换
function isSubEnvEnable() {
  if (isDebug()) {
    return true;
  }

  return getProjectEnv() === ProjectEnvEnum.Pre;
}

export { getProjectEnv, getProjectEnvConfig, isDebug, isSubEnvEnable, isProduction, isDevelopment, isOutputLog };
