import { init, RematchDispatch, RematchRootState, Models } from '@rematch/core';
import { createLogger } from 'redux-logger';
import immerPlugin from '@rematch/immer';
import { useSelector as useReduxSelector, useDispatch as useReduxDispath } from 'react-redux';
import { global } from './model';

export const store = init({
  models: {
    global,
  },
  redux: {
    middlewares: [
      createLogger({
        predicate: (getState, action) =>
          !['loading/show', '@@redux/REPLACE ', 'counter/count', 'loading/hide', '@@router/LOCATION_CHANGE'].includes(
            action.type,
          ),
        collapsed: true,
      }),
    ],
  },
  plugins: [immerPlugin()],
});

export interface RootModel extends Models<RootModel> {
  global: typeof global;
}

// export type Store = RematchStore<RootModel>;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

export function useSelector() {
  const state = useReduxSelector((state: RootState) => state.global);
  return state;
}

export function useDispatch() {
  const dispatch = useReduxDispath<Dispatch>();
  return dispatch.global;
}
