import { Avatar, Dropdown } from 'tdesign-react';
import { ChevronDownIcon } from '@tencent/ma-icons';
import { useDispatch, useSelector } from '@src/modules/tim-portal/store';
import { Link, useRouteNavigate, useLocation } from '@core';
import { SwitchLanguage } from '@src/modules/tim-portal/components/switch-language/switch-language';
import { $i18next } from '@shared/i18n';
import { toast } from '@src/libs/tdesign-plugin';

import * as styles from './nav.less';

type Props = {
  type: 'internal' | 'external';
};

export default (props: Props) => {
  const { user, isAuthenticated, home = { id: 1 } } = useSelector();
  const { type } = props;
  const navigate = useRouteNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const options = [
    {
      content: $i18next.t('tim-portal:7fc88aee:修改密码'),
      value: 1,
    },
    {
      content: $i18next.t('tim-portal:44efd179:退出登录'),
      value: 2,
    },
  ];
  const clickHandler = (data) => {
    if (data.value === 1) {
      navigate('change-password');
    }
    if (data.value === 2) {
      dispatch
        .logout()
        .then(() => {
          navigate('/login', {}, 'replace');
        })
        .catch((e) => {
          toast(e, 'error');
        });
    }
  };
  return (
    <div className={styles.nav}>
      <div className={styles.navLeft}>
        <div className={styles.logo}>
          <h1 className={$i18next.getLanguage() === 'zh' ? styles.zhH1 : styles.enH1}>
            <Link to={isAuthenticated ? '/' : '/login'}>Tencent Investment</Link>
          </h1>
        </div>
        {isAuthenticated && type === 'external' && home?.id ? (
          <div className={styles.menus}>
            <Link
              className={pathname?.indexOf('/data-entry/') !== -1 ? styles.menuActive : {}}
              to={`/data-entry/${home?.id}`}
            >
              {' '}
              {$i18next.t('tim-portal:89bc2a21:数据录入')}
            </Link>
          </div>
        ) : null}
      </div>
      <div className={styles.navRight}>
        {isAuthenticated && type === 'external' ? (
          <div className={styles.menus}>
            <Dropdown options={options} onClick={clickHandler} maxColumnWidth={'999'}>
              <div className={styles.avatar}>
                <Avatar>{$i18next.getLanguage() === 'zh' ? user?.name?.[0] : user?.nameEn?.[0]}</Avatar>
                <ChevronDownIcon />
              </div>
            </Dropdown>
          </div>
        ) : null}
        <div className={styles.menus}>
          <SwitchLanguage />
        </div>
      </div>
    </div>
  );
};
