/* eslint-disable no-param-reassign */
import { isString } from 'ts-fns';
import { source, query, compose, setup, release, request, action, renew, isSource } from 'algeb';
import { useSource } from 'algeb/react';
import type { ConstructorOf } from '@shared/typings/utils';
import { Service } from '../service';

const subscribersKey = Symbol();

export interface DataSource<T = any, U = any[] | void> {
  readonly value: T;
  readonly params: U;
  readonly get: (U) => Promise<T>;
}

export class DataService extends Service {
  constructor() {
    super();
    this[subscribersKey] = [];
  }

  /**
   * T: 值的类型
   * U: get参数的类型，必须是一个tuple
   * @param get 获取值的函数
   * @param value 默认值（第一次请求没有回来时使用）
   * @returns
   */
  source<T, U extends any[] = any[]>(get: (...args: U) => T | Promise<T>, value: T): DataSource<T, U> {
    // @ts-ignore
    return source(get, value);
  }

  compose<T, U extends any[] = any[]>(get: (...args: U) => T | Promise<T>): DataSource<T, U> {
    // @ts-ignore
    return compose(get);
  }

  action<T, U extends any[] = any[]>(act: (...args: U) => T | Promise<T>): DataSource<T, U> {
    // @ts-ignore
    return action(act);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _parse(source) {
    if (isString(source)) {
      if (!this[source as string]) {
        throw new Error(`${source} is not in DataService`);
      }
      source = this[source as string];
      if (!isSource(source)) {
        throw new Error(`${source} is not a data source`);
      }
    }
    return source;
  }

  query<T, U extends any[]>(
    source: DataSource<T, U> | string,
    ...params: U
  ): [T, (...args: any[]) => Promise<T>, Promise<T>] {
    const src = this._parse(source);

    const [data, renew, deferer] = query(src, ...params);
    const act = (...args) =>
      renew(...args).then((value) => {
        this._dispatch(src, params, value);
        return value;
      });
    return [data, act, deferer];
  }

  get<T, U extends any[]>(source: DataSource<T, U>, ...params: U): T {
    const [data] = this.query(source, ...params);
    return data;
  }

  fetch<T, U extends any[]>(source: DataSource<T, U>, ...params: U): Promise<T> {
    const [, , deferer] = this.query(source, ...params);
    return deferer;
  }

  renew<T, U extends any[]>(source: DataSource<T, U> | string, ...params: U): Promise<T> {
    const src = this._parse(source);
    return renew(src, ...params).then((value) => {
      this._dispatch(src, params, value);
      return value;
    });
  }

  request<T, U extends any[]>(source: DataSource<T, U> | string, ...params: U): Promise<T> {
    const src = this._parse(source);
    return request(src, ...params);
  }

  subscribe(fn: Function) {
    this[subscribersKey].push(fn);
  }

  unsubscribe(fn: Function) {
    this[subscribersKey].forEach((item, i) => {
      if (item === fn) {
        this[subscribersKey].splice(i, 1);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _dispatch(source, params, value) {
    this[subscribersKey].forEach((fn) => {
      fn(source, params, value);
    });
  }

  setup(run: Function) {
    return setup(run);
  }

  release<T, U extends any[] = any[]>(source: DataSource<T, U>, ...params: U): void;
  release(sources: DataSource<any, any>[]): void;
  release(source, ...params) {
    release(source, ...params);
  }

  static source<T, U extends any[] = any[]>(get: (...args: U) => T | Promise<T>, value: T): DataSource<T, U> {
    // @ts-ignore
    return source(get, value);
  }

  static compose<T>(get: Function): T {
    // @ts-ignore
    return compose(get);
  }

  static action<T, U extends any[] = any[]>(act: (...args: U) => T | Promise<T>): DataSource<T, U> {
    // @ts-ignore
    return action(act);
  }

  static query<T, U extends any[]>(
    source: DataSource<T, U> | string,
    ...params: U
  ): [T, (...args: any[]) => Promise<T>] {
    // @ts-ignore
    return query(source, ...params);
  }

  static get<T, U extends any[]>(source: DataSource<T, U>, ...params: U): T {
    const [data] = query(source, ...params);
    return data;
  }

  static renew<T, U extends any[]>(source: DataSource<T, U>, ...params: U): Promise<T> {
    return renew(source, ...params);
  }

  static request<T, U extends any[]>(source: DataSource<T, U>, ...params: U): Promise<T> {
    return request(source, ...params);
  }

  static setup(run: Function) {
    return setup(run);
  }

  static release<T, U extends any[] = any[]>(source: DataSource<T, U>, ...params: U): void;
  static release(sources: DataSource<any, any>[]): void;
  static release(source, ...params) {
    release(source, ...params);
  }

  static fetch<T, U extends any[]>(source: DataSource<T, U>, ...params: U): Promise<T> {
    const [, , deferer] = query(source, ...params);
    return deferer;
  }

  /**
   * 对当前service进行一次实例化，并运行传入的函数fn，完成运行之后执行销毁
   * 基于该逻辑，可以很好的释放内存
   * @param fn
   * @returns
   */
  static once<T, O = any>(this: ConstructorOf<T>, fn: (service: T) => O): O {
    // @ts-ignore
    const service = this.instance();
    try {
      return fn(service);
    } finally {
      service.destructor();
    }
  }
}

export function isDataSource(source: any): source is DataSource<any, any[]> {
  return isSource(source);
}

export function useDataSource<T, U extends any[] = any[]>(
  source: DataSource<T, U>,
  ...params: U
): [T, Function, boolean, Error | null] {
  const [data, renew, pending, error] = useSource(source, ...params);
  const err = error?.error;
  return [data, renew, pending, err];
}
