import "core-js";
import "regenerator-runtime/runtime";
import './init';
import { initI18n } from '@src/libs/i18n';
import { createBootstrap } from '@core';
import '@src/api/tim-portal';
import timPortal from '@src/libs/i18n/config/tim-portal.json';
import 'tdesign-react/esm/style/index.js';
import '@src/libs/reset-td.less';
import '@src/libs/reset.less';
import { render } from '@src/core/render';
import App from './app';

// 初始化国际化
initI18n([timPortal]);

const bootstrap = createBootstrap({
  router: {
    mode: '/',
  },
});

const Root = bootstrap(App);

render('#root', Root);
