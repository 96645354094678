/**
 * Footer
 */

import { $i18next } from '@shared/i18n';
import * as styles from './footer.less';

export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <p>
        Copyright &copy; 1998 - {currentYear}{' '}
        {$i18next.t('tim-portal:a78d82cd:Tencent. All Rights Reserved. 腾讯公司 版权所有')}
      </p>
    </div>
  );
}
