import type { IConfig } from './types';

export const getPortfolioReviewSheetDetail: IConfig<{ companyId: string; quarter: string }, any> = {
  summary: '获取展示区域数据',
  method: 'get',
  url: '/api/v1/investment/portfolio-review/reviews/{companyId}/detail/{quarter}/business',
  mock: false,
  response: {},
};

export const refreshPortfolioReviewSheetDetail: IConfig<{ companyId: string; quarter: string }, any> = {
  summary: '刷新展示区域数据',
  method: 'post',
  url: '/api/v1/investment/portfolio-review/reviews/{companyId}/detail/{quarter}/business/refresh',
  mock: false,
  response: {},
};
