/* eslint-disable @typescript-eslint/naming-convention */
import produce from 'immer';
import { isObject, assign } from 'ts-fns';
import { IObj } from '@shared/typings/object';
import type { ConstructorOf } from '@shared/typings/utils';
import { createTwoWayBinding } from '../utils/utils';

export class Store<T = any> {
  state: T;
  $state?: IObj;

  _subscribers: any[];
  _origin: any;

  constructor(initState?: any) {
    // eslint-disable-next-line no-nested-ternary
    const origin: any = initState ? initState : this.initState ? this.initState() : {};

    this.state = origin;
    this._subscribers = [];
    this._origin = origin;

    if (origin && typeof origin === 'object') {
      this.$state = createTwoWayBinding(this.state, (value, keyPath) => {
        this.update((state) => {
          assign(state, keyPath, value);
        });
      });
    }

    // bind to store, so that we can destruct from store
    this.update = this.update.bind(this);
    this.setState = this.setState.bind(this);
    this.getState = this.getState.bind(this);
  }
  subscribe(fn) {
    this._subscribers.push(fn);
  }
  unsubscribe(fn) {
    this._subscribers.forEach((item, i) => {
      if (item === fn) {
        this._subscribers.splice(i, 1);
      }
    });
  }
  dispatch(...args) {
    this._subscribers.forEach((fn) => {
      fn(...args);
    });
  }
  getState() {
    return this.state;
  }
  resetState() {
    this.update(this._origin);
  }
  setState(state: Partial<T>) {
    this.update((draft) => {
      if (!isObject(draft)) {
        return state;
      }
      Object.assign(draft, state);
    });
  }
  update(updator) {
    const prev = this.state;
    const next = typeof updator === 'function' ? produce(prev, updator) : updator;
    this.state = next;

    if (next && typeof next === 'object') {
      this.$state = createTwoWayBinding(this.state, (value, keyPath) => {
        this.update((state) => {
          assign(state, keyPath, value);
        });
      });
    } else {
      delete this.$state;
    }

    this.dispatch(prev, next);
  }

  initState(): T {
    return {} as any as T;
  }

  static withState<C, T = any>(this: ConstructorOf<C>, state: T): ConstructorOf<C> {
    // @ts-ignore
    return class WithStateStore extends this {
      initState(): T {
        return state;
      }
    };
  }
}
export default Store;
